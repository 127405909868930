import {getStyledValue} from '../Helpers/valueHelpers'

function calcProfiGebuehr() {
    const instances = ['AG', 'I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let profiGebuehr = 0
        
        if (this.state.profiGebuehr.checked === instance.toLowerCase()) {
            const geb13Rvg = instance === 'AG' ? this.state.geb13Rvg.calcValue : this.state['geb13Rvg' + instance].calcValue
            const geb13RvgGedeckelt = instance === 'AG' ? this.state.geb13RvgGedeckelt.calcValue : this.state['geb13RvgGedeckelt' + instance].calcValue
            
            profiGebuehr = geb13Rvg <= geb13RvgGedeckelt ? geb13Rvg : geb13RvgGedeckelt
        }

        newState = {
            ...newState,
            ['profiGebuehr' + instance]: {
                calcValue: profiGebuehr,
                styledValue: getStyledValue(profiGebuehr, 'euro', 2),
            }
        }

        value.eigenerAnwalt[instance] = profiGebuehr
    })

    return {
        value,
        newState
    }
}

export default calcProfiGebuehr