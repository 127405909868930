import {getStyledValue} from '../Helpers/valueHelpers'

function calcUbvVerfahrensGebuehr() {
    const instances = ['I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let verfahrensGebuehr = 0

        verfahrensGebuehr = this.state.ubv.checked ? this.state['ubvVerfahrensGebuehr' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue : 0

        newState = {
            ...newState,
            ['ubvVerfahrensGebuehr' + instance + 'Value']: {
                calcValue: verfahrensGebuehr,
                styledValue: getStyledValue(verfahrensGebuehr, 'euro', 2),
            },
        }

        value.eigenerAnwalt[instance] = verfahrensGebuehr
    })
    

    return {
        value,
        newState
    }
}

export default calcUbvVerfahrensGebuehr