import React from 'react'
import CalculatorBox from '../Box/CalculatorBox'

import CalculatorColumn from '../Column/CalculatorColumn'
import CalculatorSteps from '../Column/CalculatorSteps'
import CalculatorStepIndicator from '../Column/CalculatorStepIndicator'

import Checkbox from '../InputFields/Checkbox'

import Accordion from '../../Accordion/Accordion'
import {CalculatorSection4aTitle, CalculatorSection4aContent} from './CalculatorSection4a'

class CalculatorSection2 extends React.Component {
    constructor(props) {
        super(props)
        this.stepRef = React.createRef()
        this.stepIndicatorRef = React.createRef()
    }

    componentDidMount() {
        const steps = this.stepRef.current
        const stepIndicator = this.stepIndicatorRef.current
        const firstCheckboxColumn = this.stepRef.current.childNodes[0]
        const lastCheckboxColumn = this.stepRef.current.childNodes[3]

        const stepsBounds = steps.getBoundingClientRect()
        const firstCheckboxColumnBounds = firstCheckboxColumn.getBoundingClientRect()
        const lastCheckboxColumnBounds = lastCheckboxColumn.getBoundingClientRect()

        let left = stepsBounds.left - firstCheckboxColumnBounds.left + firstCheckboxColumn.offsetWidth / 2
        const width = lastCheckboxColumnBounds.left - firstCheckboxColumnBounds.left

        stepIndicator.style.height = '2px'
        stepIndicator.style.left = `${left}px`
        stepIndicator.style.width = `${width}px`
    }

    render() {
        const accordionData = [
            {
                title: <CalculatorSection4aTitle {...this.props} />,
                content: <CalculatorSection4aContent {...this.props} />
            }
        ]

        return (
            <CalculatorBox flexWrap="wrap" paddingBottom="10px">
                <CalculatorColumn flexBasis="100%" paddingBottom="20px">
                    <CalculatorSteps ref={this.stepRef}>
                        <Checkbox 
                            classes={['form-field--checkbox-step']}
                            checked={this.props.data.stufeAG.checked}
                            label="Aussergerichtlich"
                            handleChange={this.props.handles.handleChange}
                            name="stufeAG"
                        />                
                        <Checkbox 
                            classes={['form-field--checkbox-step']}
                            checked={this.props.data.stufeI1.checked}
                            label="1. Instanz"
                            handleChange={this.props.handles.handleChange}
                            name="stufeI1"
                        />                
                        <Checkbox 
                            classes={['form-field--checkbox-step']}
                            checked={this.props.data.stufeI2.checked}
                            label="2. Instanz"
                            handleChange={this.props.handles.handleChange}
                            name="stufeI2"
                        />                
                        <Checkbox 
                            classes={['form-field--checkbox-step']}
                            checked={this.props.data.stufeI3.checked}
                            label="3. Instanz"
                            handleChange={this.props.handles.handleChange}
                            name="stufeI3"
                        />                
                        <Checkbox 
                            classes={['form-field--checkbox-outside-step']}
                            checked={this.props.data.stufeVergleich.checked}
                            label="Vergleich"
                            handleChange={this.props.handles.handleChange}
                            name="stufeVergleich"
                        />                
                    </CalculatorSteps>
                    <CalculatorStepIndicator ref={this.stepIndicatorRef} />
                </CalculatorColumn>

                <CalculatorColumn flexBasis="100%">
                    <Accordion 
                        data={accordionData}
                        options={{withBorder: true}}
                    />
                </CalculatorColumn>
            </CalculatorBox>
        )
    }
}

export default CalculatorSection2