import gebuehrenTabelle from '../data/gebuehrenTabelle'

const walkTable = (wert, table, date) => {
    const dataTable = gebuehrenTabelle[table][date];
    
    if (dataTable.deckel > 0 && wert > dataTable.deckel) {
      wert = dataTable.deckel;
    }
    
    let lastData = dataTable.table[0];
    let gebuehr = 0;
    
    for (let data of dataTable.table) {    
      if (wert <= data[0]) {
        break;
      }
      
      gebuehr += ((data[0] - lastData[0]) / lastData[1]) * lastData[2];      
      
      lastData = data;
    }
    
    gebuehr += Math.ceil((wert - lastData[0]) / lastData[1]) * lastData[2];
  
    return gebuehr + dataTable.basis;
};

const calcGebuehr = (wert, gebuehrenOrdnung) => {
    const typ = gebuehrenOrdnung
    const gesetze = ['rvg', 'gkg']
    let result = {}

    gesetze.forEach((gesetz) => {
        result[gesetz] = walkTable(wert, gesetz, typ);
    })

    return result
}

export default calcGebuehr