import React from 'react'

class CalculatorBox extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fixed: false,
            height: 0,
            offsetTop: 0,
            width: 0
        }

        this.boxRef = React.createRef()

        if (this.props.stayOnTop) {
            this.handleScroll = this.handleScroll.bind(this)
        }
    }

    componentDidMount() {
        if (this.props.stayOnTop) {
            this.setState({
                height: this.boxRef.current.offsetHeight,
                offsetTop: this.boxRef.current.offsetTop,
                width: this.boxRef.current.offsetWidth
            });

            window.addEventListener('scroll', this.handleScroll)
        }
        else {
            window.removeEventListener('scroll', this.handleScroll)
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll() {
        if (window.scrollY >= this.state.offsetTop && window.matchMedia('(min-width: 960px)').matches) {
            this.setState({
                fixed: true
            });
        }
        else {
            this.setState({
                fixed: false
            });
        }
    }
    
    render() {
        const bgClass = this.props.background !== 'blue' ? this.props.background : 'blue';
        const displayClass = this.props.display === 'block' ? 'block' : 'flex';
        const tableClass = this.props.asTable ? 'as-table' : null;
    
        let classes = ['calculator-box', bgClass, displayClass, tableClass]

        
        if (this.props.hidden) {
            classes.push('hidden')
        }

        let style = {
            alignItems: this.props.alignItems,
            flexWrap: this.props.flexWrap,
            marginBottom: this.props.marginBottom,
            paddingBottom: this.props.paddingBottom,
            paddingTop: this.props.paddingTop,
            paddingRight: this.props.paddingRight,
            paddingLeft: this.props.paddingLeft,
            width: this.props.width
        }
        
        let parentStyle = {};

        if (this.state.fixed) {
            classes.push('fixed')
            style.position = 'fixed'
            style.top = 0
            style.width = this.state.width
            style.zIndex = 2000
            parentStyle.height = this.state.height + 'px'
        }

        return (
            <div className="calculator-box-parent" style={parentStyle}>
                <div className={classes.join(' ')} style={style} ref={this.boxRef}>
                    {this.props.children}
                </div>
            </div>
        )    
    }
}

export default CalculatorBox