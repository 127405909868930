import React from 'react'

import phoneIcon from '../../svg/phone.svg'
import mailIcon from '../../svg/mail.svg'

import CalculatorBox from '../CalculatorForm/Box/CalculatorBox'
import CalculatorColumn from '../CalculatorForm/Column/CalculatorColumn'
import OutputField from '../CalculatorForm/OutputFields/OutputField'
import Checkbox from '../CalculatorForm/InputFields/Checkbox'

class Flyout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            show: true
        }

        this.handleResize = this.handleResize.bind(this)
    }

    componentDidMount() {
        this.handleResize();

        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        if (window.matchMedia('(max-width: 1150px)').matches) {
            this.setState({
                show: false
            })
        }
        else {
            this.setState({
                show: true
            })
        }
    }

    onClick = (event) => {
        event.preventDefault()

        this.setState((prevState) => {
            return {
                open: ! prevState.open
            }
        })
    }

    render() {
        if (! this.state.show) {
            return (
                <></>
            )
        }

        const className = 'flyout' + (this.state.open ? ' open': '')
        const classNameLink = 'flyout-menu__link flyout-menu__link-sum' +  (this.state.open ? ' open': '')

        return (
            <>
                <div className="flyout-menu">
                    <a href="#sum" className={classNameLink} onClick={this.onClick}><span className="flyout-menu__link-big"><span className="toggle-icon"><i className="fa fa-chevron-up"></i></span>Gesamtsumme</span></a>
                    <a href="#sum" className="flyout-menu__link flyout-menu__link-phone"><span className="flyout-menu__link-icon"><img src={phoneIcon} alt="Icon Eigener Anwalt" width="40" height="40" className="flyout-menu__svg" /></span></a>
                    <a href="#sum" className="flyout-menu__link flyout-menu__link-mail"><span className="flyout-menu__link-icon"><img src={mailIcon} alt="Icon Eigener Anwalt" width="40" height="40" className="flyout-menu__svg" /></span></a>
                </div>

                <div className={className}>
                    <div className="inside">
                        <CalculatorBox background="transparent" flexWrap="wrap" paddingBottom="10px" marginBottom="0">
                            <CalculatorColumn>
                                <Checkbox 
                                    classes={['label-bold']}
                                    checked={this.props.stufeAG.checked}
                                    handleChange={this.props.handles.handleChange}
                                    label="Summe Aussergerichtlich"
                                    name="stufeAG"
                                />
                                <OutputField 
                                    margin="10px 0 20px"
                                    value={this.props.summeAussergerichtlich.styledValue} 
                                />
                            </CalculatorColumn>
                            <CalculatorColumn>
                                <Checkbox 
                                    classes={['label-bold']}
                                    checked={this.props.stufeI1.checked}
                                    handleChange={this.props.handles.handleChange}
                                    label="Summe 1. Instanz"
                                    name="stufeI1"
                                />
                                <OutputField 
                                    margin="10px 0 20px"
                                    value={this.props.summeI1.styledValue} 
                                />
                            </CalculatorColumn>
                            <CalculatorColumn>
                                <Checkbox 
                                    classes={['label-bold']}
                                    checked={this.props.stufeI2.checked}
                                    handleChange={this.props.handles.handleChange}
                                    label="Summe 2. Instanz"
                                    name="stufeI2"
                                />
                                <OutputField 
                                    margin="10px 0 20px"
                                    value={this.props.summeI2.styledValue} 
                                />
                            </CalculatorColumn>
                            <CalculatorColumn>
                                <Checkbox 
                                    classes={['label-bold']}
                                    checked={this.props.stufeI3.checked}
                                    handleChange={this.props.handles.handleChange}
                                    label="Summe 3. Instanz"
                                    name="stufeI3"
                                />
                                <OutputField 
                                    margin="10px 0 20px"
                                    value={this.props.summeI3.styledValue} 
                                />
                                <h2 className="no-caps">Gesamtprozesskosten</h2>
                                <OutputField 
                                    backgroundColor="#A67F48"
                                    margin="0 0 20px"
                                    value={this.props.gesamtprozesskosten.styledValue} 
                                />
                            </CalculatorColumn>            
                        </CalculatorBox>        
                    </div>
                </div>
            </>
        )
    }
}

export default Flyout