import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import Checkbox from '../InputFields/Checkbox'
import OutputField from '../OutputFields/OutputField'
import InputField from '../InputFields/InputField'
import InputOutputField from '../InputFields/InputOutputField'
import OutputFieldInline from '../OutputFields/OutputFieldInline'

import svgWaage from '../../../svg/waage.svg'

import Accordion from '../../Accordion/Accordion'

export function CalculatorSection3cTitle(props) {
    return (
        <>
            <h2 className="no-caps">Gerichtskosten</h2>
            <div className="accordion__trigger-column">
                <Checkbox 
                    checked={props.data.gesamtkostenGericht.checked}
                    label="Gesamtkosten"
                    handleChange={props.handles.handleChange}
                    name="gesamtkostenGericht"
                />
                <OutputFieldInline 
                    value={props.data.gesamtkostenGerichtValue.styledValue} 
                />
            </div>
        </>
    )
}

export function CalculatorSection3cContent(props) {
    const isInstanz1 = props.data.stufeI1.checked
    const isInstanz2 = props.data.stufeI2.checked
    const isInstanz3 = props.data.stufeI3.checked

    const contentI1 = {
        title: <p>1. Instanz</p>,
        gerichtsverfahren:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gerichtsverfahrenKVI1"
                inputName="gerichtsverfahrenKVI1"
                inputValue={props.data.gerichtsverfahrenKVI1.inputValue}
                label="Gerichtsverfahren KV"
                outputValue={props.data.gerichtsverfahrenKVI1Value.styledValue}
                type="number"
            />,
        sachverstaendiger:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sachverstaendigerI1"
                label="Sachverständiger/Zeuge etc."
                name="sachverstaendigerI1"
                type="euro"
                value={props.data.sachverstaendigerI1.inputValue}
            />,
        gesamt:
            <OutputField
            backgroundColor="#061429"
            bold={true}
            label="Gesamtkosten"
            value={props.data.gerichtskostenGesamtI1.styledValue} 
        />
    }

    const contentI2 = {
        title: <p>2. Instanz</p>,
        gerichtsverfahren:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gerichtsverfahrenKVI2"
                inputName="gerichtsverfahrenKVI2"
                inputValue={props.data.gerichtsverfahrenKVI2.inputValue}
                label="Gerichtsverfahren KV"
                outputValue={props.data.gerichtsverfahrenKVI2Value.styledValue}
                type="number"
            />,
        sachverstaendiger:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sachverstaendigerI2"
                label="Sachverständiger/Zeuge etc."
                name="sachverstaendigerI2"
                type="euro"
                value={props.data.sachverstaendigerI2.inputValue}
            />,
        gesamt:
            <OutputField
            backgroundColor="#061429"
            bold={true}
            label="Gesamtkosten"
            value={props.data.gerichtskostenGesamtI2.styledValue} 
        /> 
    }

    const contentI3 = {
        title: <p>3. Instanz</p>,
        gerichtsverfahren:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gerichtsverfahrenKVI3"
                inputName="gerichtsverfahrenKVI3"
                inputValue={props.data.gerichtsverfahrenKVI3.inputValue}
                label="Gerichtsverfahren KV"
                outputValue={props.data.gerichtsverfahrenKVI3Value.styledValue}
                type="number"
            />,
        sachverstaendiger:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sachverstaendigerI3"
                label="Sachverständiger/Zeuge etc."
                name="sachverstaendigerI3"
                type="euro"
                value={props.data.sachverstaendigerI3.inputValue}
            />,
        gesamt:
            <OutputField
            backgroundColor="#061429"
            bold={true}
            label="Gesamtkosten"
            value={props.data.gerichtskostenGesamtI3.styledValue} 
        /> 
    }

    if (props.mobile) {
        const contentI1Processed = Object.keys(contentI1).map(key => {
            if (key === 'title') {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI1[key]}
                </div>
            )
        });

        const contentI2Processed = Object.keys(contentI2).map(key => {
            if (key === 'title') {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI2[key]}
                </div>
            )
        });

        const contentI3Processed = Object.keys(contentI3).map(key => {
            if (key === 'title') {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI3[key]}
                </div>
            )
        });

        const mobileAccordionData = [
            {
                title: <h2>{contentI1.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz1 ? contentI1Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz1,
                open: false
            },
            {
                title: <h2>{contentI2.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz2 ? contentI2Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz2,
                open: false
            },
            {
                title: <h2>{contentI3.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz3 ? contentI3Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz3,
                open: false
            }
        ]        

        return (
            <>
                <CalculatorBox display="block" background="lightblue" paddingBottom="0px">
                    <Accordion
                        openOnChange={true}
                        data={mobileAccordionData}
                        options={{withBorder: false}}
                    />
                </CalculatorBox>
            </>
        )
    }

    return (
        <>
            <CalculatorBox background="lightblue">
                <CalculatorColumn />
                <CalculatorColumn textAlign="center">
                    {contentI1.title}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {contentI2.title}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {contentI3.title}
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.gerichtsverfahren
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.gerichtsverfahren
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.gerichtsverfahren
                : null}
                </CalculatorColumn>
            </CalculatorBox>                    

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.sachverstaendiger
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.sachverstaendiger
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.sachverstaendiger
                    : null}
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.gesamt
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.gesamt
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.gesamt
                    : null}
                </CalculatorColumn>
            </CalculatorBox>
        </>
    )
}