import React from 'react'

import Checkbox from './Checkbox'
import OutputField from '../OutputFields/OutputField';

const CheckboxOuput = (props) => {
    const visibilityClass = props.withOpacity && ! props.checked ? 'disabled' : null

    const classes = [
        'form-field__checkbox-output',
        visibilityClass
    ]

    return (
        <div className={classes.join(' ')}>
            <label className="form-field__label form-field__label--co">
                <span className="form-field__label-text">{props.label}</span>
                <div className="form-field__co-columns">
                    <Checkbox                            
                        checked={props.checked}
                        label={props.checkboxLabel}
                        handleChange={props.handleChange}
                        name={props.name}
                        value={props.checkboxValue}
                    />
                    <OutputField
                        value={props.outputValue}
                    />
                </div>
            </label>
        </div>
    )
}

export default CheckboxOuput