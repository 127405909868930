const faktorenRvg = {
    mandanten: {
        i1: {
            20: 3.3,
            19: 3.3,
            18: 3.3,
            17: 3.3,
            16: 3.3,
            15: 3.3,
            14: 3.3,
            13: 3.3,
            12: 3.3,
            11: 3.3,
            10: 3.3,
            9: 3.3,
            8: 3.3,
            7: 3.1,
            6: 2.8,
            5: 2.5,
            4: 2.2,
            3: 1.9,
            2: 1.6,
            1: 1.3            
        },
        i2: {
            20: 3.6,
            19: 3.6,
            18: 3.6,
            17: 3.6,
            16: 3.6,
            15: 3.6,
            14: 3.6,
            13: 3.6,
            12: 3.6,
            11: 3.6,
            10: 3.6,
            9: 3.6,
            8: 3.6,
            7: 3.4,
            6: 3.1,
            5: 2.8,
            4: 2.5,
            3: 2.2,
            2: 1.9,
            1: 1.6
        },
        i3: {
            20: 4.3,
            19: 4.3,
            18: 4.3,
            17: 4.3,
            16: 4.3,
            15: 4.3,
            14: 4.3,
            13: 4.3,
            12: 4.3,
            11: 4.3,
            10: 4.3,
            9: 4.3,
            8: 4.3,
            7: 4.1,
            6: 3.8,
            5: 3.5,
            4: 3.2,
            3: 2.9,
            2: 2.6,
            1: 2.3
        }
    },
    gegnerAg: {
        i1: {
            20: 3.3,
            19: 3.3,
            18: 3.3,
            17: 3.3,
            16: 3.3,
            15: 3.3,
            14: 3.3,
            13: 3.3,
            12: 3.3,
            11: 3.3,
            10: 3.3,
            9: 3.3,
            8: 3.3,
            7: 3.1,
            6: 2.8,
            5: 2.5,
            4: 2.2,
            3: 1.9,
            2: 1.6,
            1: 1.3            
        },
        i2: {
            20: 3.6,
            19: 3.6,
            18: 3.6,
            17: 3.6,
            16: 3.6,
            15: 3.6,
            14: 3.6,
            13: 3.6,
            12: 3.6,
            11: 3.6,
            10: 3.6,
            9: 3.6,
            8: 3.6,
            7: 3.4,
            6: 3.1,
            5: 2.8,
            4: 2.5,
            3: 2.2,
            2: 1.9,
            1: 1.6                        
        },
        i3: {
            20: 4.3,
            19: 4.3,
            18: 4.3,
            17: 4.3,
            16: 4.3,
            15: 4.3,
            14: 4.3,
            13: 4.3,
            12: 4.3,
            11: 4.3,
            10: 4.3,
            9: 4.3,
            8: 4.3,
            7: 4.1,
            6: 3.8,
            5: 3.5,
            4: 3.2,
            3: 2.9,
            2: 2.6,
            1: 2.3
        }
    },
    gegnerRaVg: {
        i1: {
            20: 10.4,
            19: 10.4,
            18: 10.4,
            17: 10.4,
            16: 10.4,
            15: 10.4,
            14: 10.4,
            13: 10.4,
            12: 10.4,
            11: 10.4,
            10: 10.4,
            9: 10.4,
            8: 10.4,
            7: 9.1,
            6: 7.8,
            5: 6.5,
            4: 5.2,
            3: 3.9,
            2: 2.6,
            1: 1.3            
        },
        i2: {
            20: 12.8,
            19: 12.8,
            18: 12.8,
            17: 12.8,
            16: 12.8,
            15: 12.8,
            14: 12.8,
            13: 12.8,
            12: 12.8,
            11: 12.8,
            10: 12.8,
            9: 12.8,
            8: 12.8,
            7: 11.2,
            6: 9.6,
            5: 8.0,
            4: 6.4,
            3: 4.8,
            2: 3.2,
            1: 1.6                        
        },
        i3: {
            20: 18.4,
            19: 18.4,
            18: 18.4,
            17: 18.4,
            16: 18.4,
            15: 18.4,
            14: 18.4,
            13: 18.4,
            12: 18.4,
            11: 18.4,
            10: 18.4,
            9: 18.4,
            8: 18.4,
            7: 16.1,
            6: 13.8,
            5: 11.5,
            4: 9.2,
            3: 6.9,
            2: 4.6,
            1: 2.3                        
        }
    },
    gegnerRaTg: {
        i1: {
            20: 9.6,
            19: 9.6,
            18: 9.6,
            17: 9.6,
            16: 9.6,
            15: 9.6,
            14: 9.6,
            13: 9.6,
            12: 9.6,
            11: 9.6,
            10: 9.6,
            9: 9.6,
            8: 9.6,
            7: 8.4,
            6: 7.2,
            5: 6,
            4: 4.8,
            3: 3.6,
            2: 2.4,
            1: 1.2            
        },
        i2: {
            20: 9.6,
            19: 9.6,
            18: 9.6,
            17: 9.6,
            16: 9.6,
            15: 9.6,
            14: 9.6,
            13: 9.6,
            12: 9.6,
            11: 9.6,
            10: 9.6,
            9: 9.6,
            8: 9.6,
            7: 8.4,
            6: 7.2,
            5: 6,
            4: 4.8,
            3: 3.6,
            2: 2.4,
            1: 1.2                        
        },
        i3: {
            20: 12,
            19: 12,
            18: 12,
            17: 12,
            16: 12,
            15: 12,
            14: 12,
            13: 12,
            12: 12,
            11: 12,
            10: 12,
            9: 12,
            8: 12,
            7: 10.5,
            6: 9,
            5: 7.5,
            4: 6,
            3: 4.5,
            2: 3,
            1: 1.5            
        }
    }
}

export default faktorenRvg