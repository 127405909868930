import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import RadioButtons from '../InputFields/RadioButtons';
import OutputField from '../OutputFields/OutputField'

export function CalculatorSection4aTitle(props) {
    const radioButtons = [
        {
            category: 'appState',
            checked: props.data.gebuehrenOrdnung === '2021',
            label: 'RVG/GKG ab 01.01.2021',
            name: 'gebuehrenOrdnung',
            value: '2021'
        },
        {
            category: 'appState',
            checked: props.data.gebuehrenOrdnung === '2020',
            label: 'RVG/GKG bis 31.12.2020',
            name: 'gebuehrenOrdnung',
            value: '2020'
        },
        {
            category: 'appState',
            checked: props.data.gebuehrenOrdnung === '2013',
            label: 'RVG/GKG ab 31.07.2013',
            name: 'gebuehrenOrdnung',
            value: '2013'
        },
    ]

    return (
        <>
            <h2 className="with-switch">
                <RadioButtons
                    data={radioButtons}
                    fontSize="small"
                    handleChange={props.handles.handleChange}
                />
            </h2>
        </>
    )
}

export function CalculatorSection4aContent(props) {
    const output1 = (
        <>
            <OutputField
                label="1,0 Geb. § 13 RVG"
                value={props.data.geb13Rvg.styledValue} 
            /> 
        </>
    )

    const output2 = (
        <>
            <OutputField
                label="1,0 Geb. § 34 GKG"
                value={props.data.geb34Gkg.styledValue} 
            /> 
        </>
    )
    
    if (props.mobile) {
        return (
            <CalculatorBox display="block" background="white">
                <div className="mobile-row">
                    {output1}
                </div>

                <div className="mobile-row">
                    {output2}
                </div>
            </CalculatorBox>
        )
    }

    return (
        <>
            <CalculatorBox background="white">
                <CalculatorColumn>
                    {output1}
                </CalculatorColumn>
                <CalculatorColumn>
                    {output2}
                </CalculatorColumn>

                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>
        </>
    )
}

