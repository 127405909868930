import React from 'react'

class AccordionItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: true,
            maxHeight: 'none'
        }

        this.originalHeight = 0

        this.accContentRef = React.createRef()
    }

    componentDidMount() {
        const accContent = this.accContentRef.current
        this.originalHeight = accContent.offsetHeight

        this.setState({
            open: this.props.open,
            maxHeight: this.props.open ? this.originalHeight : 0
        })
    }

    onClick = (event) => {
        event.preventDefault()

        this.setState({
            open: !this.state.open,
            maxHeight: ! this.state.open ? this.originalHeight : 0
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.indicator !== nextProps.indicator && nextProps.indicator === true) {
            this.setState({
                maxHeight: 'none',
                open: true
            }, () => {
                const accContent = this.accContentRef.current
                this.originalHeight = accContent.offsetHeight
            })
        }

        if (this.state.open) {
            this.setState({
                maxHeight: 'none'
            }, () => {
                const accContent = this.accContentRef.current
                this.originalHeight = accContent.offsetHeight
            })
        }
    }

    render() {
        return (
            <div className={`accordion__element${this.state.open ? ' open' : ''}`}>
                <div 
                    className="accordion__trigger"
                    onClick={this.onClick} 
                >
                    <span className="accordion__trigger-icon">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.2187 7.61572L13.0521 18.7813C12.9057 18.9279 12.7071 19.0103 12.5 19.0103C12.2929 19.0103 12.0943 18.9279 11.9479 18.7813L0.78125 7.61572" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    {this.props.title}
                </div>
                <div className="accordion__content" ref={this.accContentRef} style={{maxHeight: this.state.maxHeight}}>
                    {this.props.content}
                </div>
            </div>
        )
    }
}

export default AccordionItem