import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcUbvTerminsGebuehr() {
    const instances = ['I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let terminsGebuehr = 0

        if (this.state.ubv.checked) {
            terminsGebuehr = this.state['ubvTerminsGebuehr' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue
    
            if (this.state.mehrvergleich.checked) {
                const sumMehrwertbetragStreitwert = this.state['mehrvergleich' + instance].calcValue + this.state['streitwert' + instance].calcValue
                terminsGebuehr = calcGebuehr(sumMehrwertbetragStreitwert, this.state.gebuehrenOrdnung).rvg * this.state['ubvTerminsGebuehr' + instance].calcValue
            }
        }

        value.eigenerAnwalt[instance] = terminsGebuehr

        newState = {
            ...newState,
            ['ubvTerminsGebuehr' + instance + 'Value']: {
                calcValue: terminsGebuehr,
                styledValue: getStyledValue(terminsGebuehr, 'euro', 2),
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcUbvTerminsGebuehr