import React from 'react'
import Header from '../StaticComponents/Header'
import Calculator from '../Calculator/Calculator'
import Footer from '../StaticComponents/Footer'

const App = () => {
  return (
    <div className="pkr">
      <Header/>
      <main className="main">
          <div className="inside">
              <h1>Prozesskostenrechner</h1>

              <form className="calculator">
                <Calculator />
              </form>

              <Footer/>
          </div>
      </main>
    </div>
  )
}

export default App
