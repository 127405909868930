import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcGerichtsverfahrenKV() {
    const instances = ['I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let kv = this.state['geb34Gkg' + instance].calcValue * this.state['gerichtsverfahrenKV' + instance].calcValue

        if (this.state.mehrvergleich.checked) {
            const streitwertPlusMehrvergleich = this.state['streitwert' + instance].calcValue + this.state['mehrvergleich' + instance].calcValue
            const satzGesamt = calcGebuehr(streitwertPlusMehrvergleich, this.state.gebuehrenOrdnung).gkg * this.state['gerichtsverfahrenKV' + instance].calcValue
            const zuslimit = 30000000 - Math.min(this.state['streitwert' + instance].calcValue, 30000000)
            const beide = this.state['geb34Gkg' + instance].calcValue * this.state['gerichtsverfahrenKV' + instance].calcValue + calcGebuehr(Math.min(this.state['mehrvergleich' + instance].calcValue, zuslimit), this.state.gebuehrenOrdnung).gkg / 4

            kv = Math.min(beide, satzGesamt)
        }

        newState = {
            ...newState,
            ['gerichtsverfahrenKV' + instance + 'Value']: {
                calcValue: kv,
                styledValue: getStyledValue(kv, 'euro', 2),    
            }
        }

        value.gericht[instance] = kv
    })

    return {
        value,
        newState
    }

}

export default calcGerichtsverfahrenKV