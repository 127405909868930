import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import RadioButtons from '../InputFields/RadioButtons';
import Checkbox from '../InputFields/Checkbox'
import OutputField from '../OutputFields/OutputField'
import InputField from '../InputFields/InputField'
import InputOutputField from '../InputFields/InputOutputField'
import CheckboxOutput from '../InputFields/CheckboxOutput'
import OutputFieldInline from '../OutputFields/OutputFieldInline'

import svgEigenerAnwalt from '../../../svg/eigenerAnwalt.svg'
import CheckboxGroup from '../InputFields/CheckboxeGroup'

import Accordion from '../../Accordion/Accordion'

export function CalculatorSection3aTitle(props) {
    return (
        <>
            <h2 className="no-caps">Eigener Anwalt</h2>
            <div className="accordion__trigger-column">
                <Checkbox 
                    checked={props.data.gesamtkostenEigenerAnwalt.checked}
                    label="Gesamtkosten"
                    handleChange={props.handles.handleChange}
                    name="gesamtkostenEigenerAnwalt"
                />
                <OutputFieldInline 
                    value={props.data.gesamtKostenEigenerAnwaltValue.styledValue} 
                />
            </div>
        </>
    )
}

export function CalculatorSection3aContent(props) {
    const radioButtons = [
        {
            category: 'appState',
            checked: props.data.honorar === 'rvg' ? true : false,
            label: 'RVG',
            name: 'honorar',
            value: 'rvg'
        },
        {
            category: 'appState',
            checked: props.data.honorar === 'stunden' ? true : false,
            label: 'Stundenhonorar',
            name: 'honorar',
            value: 'stunden'
        }
    ]

    const checkboxes = [
        {
            checked: props.data.mehrvergleich.checked,
            label: 'Mehrvergleich',
            name: 'mehrvergleich'
        },
        {
            checked: props.data.erfolgshonorar.checked,
            label: 'Erfolgshonorar',
            name: 'erfolgshonorar'
        },
        {
            checked: props.data.ubv.checked,
            label: 'Mit UBV',
            name: 'ubv'
        }
    ]

    const isAussergerichtlich = props.data.stufeAG.checked
    const isInstanz1 = props.data.stufeI1.checked
    const isInstanz2 = props.data.stufeI2.checked
    const isInstanz3 = props.data.stufeI3.checked

    const showErfolgshonorar = props.data.erfolgshonorar.checked

    const contentAG = {
        title: <p>Aussergerichtlich</p>,
        streitwert: 
            <OutputField
                label="Streitwert"
                value={props.data.streitwert.styledValue} 
            /> 
        ,
        geschaeftsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="geschaeftsGebuehrvv2300"
                inputName="geschaeftsGebuehrvv2300"
                inputValue={props.data.geschaeftsGebuehrvv2300.inputValue}
                label="Geschäftsgebühr VV2300"
                outputValue={props.data.geschaeftsGebuehrvv2300Value.styledValue}
                type="number"
            />
        ,
        einigungsgebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checked={props.data.einigungsGebuehrAG.checked}
                handleChange={props.handles.handleChange}
                label="Einigungsgebühr VV1000"
                name="einigungsGebuehrAG"
                outputValue={props.data.einigungsGebuehrAGValue.styledValue}
            />
        ,
        profigebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checkboxValue="ag"
                checked={props.data.profiGebuehr.checked === 'ag'}
                handleChange={props.handles.handleChange}
                label="1,0 Profigebühr"
                name="profiGebuehr"
                outputValue={props.data.profiGebuehrAG.styledValue}
                withOpacity="1"
            />
        ,
        auslagen: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="auslagenAG"
                label="Auslagen VV7001, 7002"
                name="auslagenAG"
                type="euro"
                value={props.data.auslagenAG.inputValue}
            />
        ,
        privgutachten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="privGutachtenAG"
                label="Private Gutachten etc."
                name="privGutachtenAG"
                type="euro"
                value={props.data.privGutachtenAG.inputValue}
            />
        ,
        ust: 
            <OutputField
                label="19% Ust."
                value={props.data.Ust19AG.styledValue} 
            /> 
        ,
        gesamteigener: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten eigener Anwalt"
                value={props.data.gesamtEigenerAAG.styledValue} 
            /> 
        ,
        erfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="erfolgsHonorarAG"
                inputName="erfolgsHonorarAG"
                inputValue={props.data.erfolgsHonorarAG.inputValue}
                label="Aufschlag bei Erfolg"
                outputValue={props.data.erfolgsHonorarAGValue.styledValue}
                type="percent"
            />
        ,
        nichterfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="nichtErfolgsHonorarAG"
                inputName="nichtErfolgsHonorarAG"
                inputValue={props.data.nichtErfolgsHonorarAG.inputValue}
                label="Abzug bei Nicht-Erfolg"
                outputValue={props.data.nichtErfolgsHonorarAGValue.styledValue}
                type="percent"
            />
        ,
        gesamteigenererfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Erfolg"
                value={props.data.gesamtEigenerErfolgAG.styledValue} 
            /> 
        ,
        gesamteigenernichterfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Nicht-Erfolg"
                value={props.data.gesamtEigenerNichtErfolgAG.styledValue} 
            /> 
    }

    const contentI1 = {
        title: <p>1. Instanz</p>,
        streitwert: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="streitwertI1"
                label="Streitwert"
                name="streitwertI1"
                type="euro"
                value={props.data.streitwertI1.inputValue}
            />
        ,
        mehrvergleich: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="mehrvergleichI1"
                label="Vergleich mit Mehrwertbetrag"
                name="mehrvergleichI1"
                type="euro"
                value={props.data.mehrvergleichI1.inputValue}
            />
        ,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="verfahrensGebuehrvv3100I1"
                inputName="verfahrensGebuehrvv3100I1"
                inputValue={props.data.verfahrensGebuehrvv3100I1.inputValue}
                label="Verfahrensgeb. VV3100, 1008"
                outputValue={props.data.verfahrensGebuehrvv3100I1Value.styledValue}
                type="number"
            />
        ,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Verfahrensgebühr"
                value={props.data.diffVFGebuehrI1.styledValue}
            />
        ,
        abzuganrechteil: 
            <OutputField
                label="Abzgl. anrechenbarer Teil"
                value={props.data.abzugAnrechTeil.styledValue}
            />
        ,
        terminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="terminsGebuehrI1"
                inputName="terminsGebuehrI1"
                inputValue={props.data.terminsGebuehrI1.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.terminsGebuehrI1Value.styledValue}
                type="number"
            />
        ,
        zusatzgebuehr: 
            <CheckboxOutput
                checkboxLabel="Ja"
                checked={props.data.zusatzGebuehrVV1010I1.checked}
                handleChange={props.handles.handleChange}
                label="0,3 Zusatzgebühr VV1010"
                name="zusatzGebuehrVV1010I1"
                outputValue={props.data.zusatzGebuehrVV1010I1Value.styledValue}
            />
        ,
        einigungsgebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checked={props.data.einigungsGebuehrI1.checked}
                handleChange={props.handles.handleChange}
                label="Einigungsgebühr VV1003f"
                name="einigungsGebuehrI1"
                outputValue={props.data.einigungsGebuehrI1Value.styledValue}
            />
        ,
        diffeinigungsgebuehr: 
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.diffEinigungsGebuehrI1.styledValue}
            />
        ,
        profigebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checkboxValue="i1"
                checked={props.data.profiGebuehr.checked === 'i1'}
                handleChange={props.handles.handleChange}
                label="1,0 Profigebühr"
                name="profiGebuehr"
                outputValue={props.data.profiGebuehrI1.styledValue}
                withOpacity="1"
            />
        ,
        auslagen: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="auslagenI1"
                label="Auslagen VV7002"
                name="auslagenI1"
                type="euro"
                value={props.data.auslagenI1.inputValue}
            />
        ,
        ubvverfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvVerfahrensGebuehrI1"
                inputName="ubvVerfahrensGebuehrI1"
                inputValue={props.data.ubvVerfahrensGebuehrI1.inputValue}
                label="UBV-Verfahrensgeb. gem. 3401"
                outputValue={props.data.ubvVerfahrensGebuehrI1Value.styledValue}
                type="number"
            />
        ,
        ubvterminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvTerminsGebuehrI1"
                inputName="ubvTerminsGebuehrI1"
                inputValue={props.data.ubvTerminsGebuehrI1.inputValue}
                label="UBV-Terminsgebühr gem. 3402"
                outputValue={props.data.ubvTerminsGebuehrI1Value.styledValue}
                type="number"
            />
        ,
        ubvauslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="ubvAuslagenI1"
                label="UBV-Auslagen"
                name="ubvAuslagenI1"
                type="euro"
                value={props.data.ubvAuslagenI1.inputValue}
            />
        ,
        sonstkosten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sonstKostenI1"
                label="Sonst. Kosten VV7000, 7003ff"
                name="sonstKostenI1"
                type="euro"
                value={props.data.sonstKostenI1.inputValue}
            />
        ,
        privgutachten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="privGutachtenI1"
                label="Private Gutachten etc."
                name="privGutachtenI1"
                type="euro"
                value={props.data.privGutachtenI1.inputValue}
            />
        ,
        ust: 
            <OutputField
                label="19% Ust."
                value={props.data.Ust19I1.styledValue} 
            /> 
        ,
        gesamteigener: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten eigener Anwalt"
                value={props.data.gesamtEigenerAI1.styledValue} 
            /> 
        ,
        erfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="erfolgsHonorarI1"
                inputName="erfolgsHonorarI1"
                inputValue={props.data.erfolgsHonorarI1.inputValue}
                label="Aufschlag bei Erfolg"
                outputValue={props.data.erfolgsHonorarI1Value.styledValue}
                type="percent"
            />
        ,
        nichterfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="nichtErfolgsHonorarI1"
                inputName="nichtErfolgsHonorarI1"
                inputValue={props.data.nichtErfolgsHonorarI1.inputValue}
                label="Abzug bei Nicht-Erfolg"
                outputValue={props.data.nichtErfolgsHonorarI1Value.styledValue}
                type="percent"
            />
        ,
        gesamteigenererfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Erfolg"
                value={props.data.gesamtEigenerErfolgI1.styledValue} 
            /> 
        ,
        gesamteigenernichterfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Nicht-Erfolg"
                value={props.data.gesamtEigenerNichtErfolgI1.styledValue} 
            /> 
    }

    const contentI2 = {
        title: <p>2. Instanz</p>,
        streitwert: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="streitwertI2"
                label="Streitwert"
                name="streitwertI2"
                type="euro"
                value={props.data.streitwertI2.inputValue}
            />
        ,
        mehrvergleich: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="mehrvergleichI2"
                label="Vergleich mit Mehrwertbetrag"
                name="mehrvergleichI2"
                type="euro"
                value={props.data.mehrvergleichI2.inputValue}
            />
        ,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="verfahrensGebuehrvv3100I2"
                inputName="verfahrensGebuehrvv3100I2"
                inputValue={props.data.verfahrensGebuehrvv3100I2.inputValue}
                label="Verfahrensgeb. VV3100, 1008"
                outputValue={props.data.verfahrensGebuehrvv3100I2Value.styledValue}
                type="number"
            />
        ,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Verfahrensgebühr"
                value={props.data.diffVFGebuehrI2.styledValue}
            />
        ,
        terminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="terminsGebuehrI2"
                inputName="terminsGebuehrI2"
                inputValue={props.data.terminsGebuehrI2.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.terminsGebuehrI2Value.styledValue}
                type="number"
            />
        ,
        zusatzgebuehr: 
            <CheckboxOutput
                checkboxLabel="Ja"
                checked={props.data.zusatzGebuehrVV1010I2.checked}
                handleChange={props.handles.handleChange}
                label="0,3 Zusatzgebühr VV1010"
                name="zusatzGebuehrVV1010I2"
                outputValue={props.data.zusatzGebuehrVV1010I2Value.styledValue}
            />
        ,
        einigungsgebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checked={props.data.einigungsGebuehrI2.checked}
                handleChange={props.handles.handleChange}
                label="Einigungsgebühr VV1003f"
                name="einigungsGebuehrI2"
                outputValue={props.data.einigungsGebuehrI2Value.styledValue}
            />
        ,
        diffeinigungsgebuehr: 
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.diffEinigungsGebuehrI2.styledValue}
            />
        ,
        profigebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checkboxValue="i2"
                checked={props.data.profiGebuehr.checked === 'i2'}
                handleChange={props.handles.handleChange}
                label="1,0 Profigebühr"
                name="profiGebuehr"
                outputValue={props.data.profiGebuehrI2.styledValue}
                withOpacity="1"
            />
        ,
        auslagen: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="auslagenI2"
                label="Auslagen VV7002"
                name="auslagenI2"
                type="euro"
                value={props.data.auslagenI2.inputValue}
            />
        ,
        ubvverfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvVerfahrensGebuehrI2"
                inputName="ubvVerfahrensGebuehrI2"
                inputValue={props.data.ubvVerfahrensGebuehrI2.inputValue}
                label="UBV-Verfahrensgeb. gem. 3401"
                outputValue={props.data.ubvVerfahrensGebuehrI2Value.styledValue}
                type="number"
            />
        ,
        ubvterminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvTerminsGebuehrI2"
                inputName="ubvTerminsGebuehrI2"
                inputValue={props.data.ubvTerminsGebuehrI2.inputValue}
                label="UBV-Terminsgebühr gem. 3402"
                outputValue={props.data.ubvTerminsGebuehrI2Value.styledValue}
                type="number"
            />
        ,
        ubvauslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="ubvAuslagenI2"
                label="UBV-Auslagen"
                name="ubvAuslagenI2"
                type="euro"
                value={props.data.ubvAuslagenI2.inputValue}
            />
        ,        
        sonstkosten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sonstKostenI2"
                label="Sonst. Kosten VV7000, 7003ff"
                name="sonstKostenI2"
                type="euro"
                value={props.data.sonstKostenI2.inputValue}
            />
        ,
        privgutachten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="privGutachtenI2"
                label="Private Gutachten etc."
                name="privGutachtenI2"
                type="euro"
                value={props.data.privGutachtenI2.inputValue}
            />
        ,
        ust: 
            <OutputField
                label="19% Ust."
                value={props.data.Ust19I2.styledValue} 
            /> 
        ,
        gesamteigener: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten eigener Anwalt"
                value={props.data.gesamtEigenerAI2.styledValue} 
            /> 
        ,
        erfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="erfolgsHonorarI2"
                inputName="erfolgsHonorarI2"
                inputValue={props.data.erfolgsHonorarI2.inputValue}
                label="Aufschlag bei Erfolg"
                outputValue={props.data.erfolgsHonorarI2Value.styledValue}
                type="percent"
            />
        ,
        nichterfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="nichtErfolgsHonorarI2"
                inputName="nichtErfolgsHonorarI2"
                inputValue={props.data.nichtErfolgsHonorarI2.inputValue}
                label="Abzug bei Nicht-Erfolg"
                outputValue={props.data.nichtErfolgsHonorarI2Value.styledValue}
                type="percent"
            />
        ,
        gesamteigenererfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Erfolg"
                value={props.data.gesamtEigenerErfolgI2.styledValue} 
            /> 
        ,
        gesamteigenernichterfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Nicht-Erfolg"
                value={props.data.gesamtEigenerNichtErfolgI2.styledValue} 
            /> 
    }

    const contentI3 = {
        title: <p>3. Instanz</p>,
        streitwert: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="streitwertI3"
                label="Streitwert"
                name="streitwertI3"
                type="euro"
                value={props.data.streitwertI3.inputValue}
            />
        ,
        mehrvergleich: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="mehrvergleichI3"
                label="Vergleich mit Mehrwertbetrag"
                name="mehrvergleichI3"
                type="euro"
                value={props.data.mehrvergleichI3.inputValue}
            />
        ,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="verfahrensGebuehrvv3100I3"
                inputName="verfahrensGebuehrvv3100I3"
                inputValue={props.data.verfahrensGebuehrvv3100I3.inputValue}
                label="Verfahrensgeb. VV3100, 1008"
                outputValue={props.data.verfahrensGebuehrvv3100I3Value.styledValue}
                type="number"
            />
        ,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Verfahrensgebühr"
                value={props.data.diffVFGebuehrI3.styledValue}
            />
        ,
        terminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="terminsGebuehrI3"
                inputName="terminsGebuehrI3"
                inputValue={props.data.terminsGebuehrI3.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.terminsGebuehrI3Value.styledValue}
                type="number"
            />
        ,
        einigungsgebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checked={props.data.einigungsGebuehrI3.checked}
                handleChange={props.handles.handleChange}
                label="Einigungsgebühr VV1003f"
                name="einigungsGebuehrI3"
                outputValue={props.data.einigungsGebuehrI3Value.styledValue}
            />
        ,
        diffeinigungsgebuehr: 
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.diffEinigungsGebuehrI3.styledValue}
            />
        ,
        profigebuehr: 
            <CheckboxOutput 
                checkboxLabel="Ja"
                checkboxValue="i3"
                checked={props.data.profiGebuehr.checked === 'i3'}
                handleChange={props.handles.handleChange}
                label="1,0 Profigebühr"
                name="profiGebuehr"
                outputValue={props.data.profiGebuehrI3.styledValue}
                withOpacity="1"
            />
        ,
        auslagen: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="auslagenI3"
                label="Auslagen VV7002"
                name="auslagenI3"
                type="euro"
                value={props.data.auslagenI3.inputValue}
            />
        ,
        ubvverfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvVerfahrensGebuehrI3"
                inputName="ubvVerfahrensGebuehrI3"
                inputValue={props.data.ubvVerfahrensGebuehrI3.inputValue}
                label="UBV-Verfahrensgeb. gem. 3401"
                outputValue={props.data.ubvVerfahrensGebuehrI3Value.styledValue}
                type="number"
            />
        ,
        ubvterminsgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="ubvTerminsGebuehrI3"
                inputName="ubvTerminsGebuehrI3"
                inputValue={props.data.ubvTerminsGebuehrI3.inputValue}
                label="UBV-Terminsgebühr gem. 3402"
                outputValue={props.data.ubvTerminsGebuehrI3Value.styledValue}
                type="number"
            />
        ,
        ubvauslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="ubvAuslagenI3"
                label="UBV-Auslagen"
                name="ubvAuslagenI3"
                type="euro"
                value={props.data.ubvAuslagenI3.inputValue}
            />
        ,        
        sonstkosten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sonstKostenI3"
                label="Sonst. Kosten VV7000, 7003ff"
                name="sonstKostenI3"
                type="euro"
                value={props.data.sonstKostenI3.inputValue}
            />
        ,
        privgutachten: 
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="privGutachtenI3"
                label="Private Gutachten etc."
                name="privGutachtenI3"
                type="euro"
                value={props.data.privGutachtenI3.inputValue}
            />
        ,
        ust: 
            <OutputField
                label="19% Ust."
                value={props.data.Ust19I3.styledValue} 
            /> 
        ,
        gesamteigener: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten eigener Anwalt"
                value={props.data.gesamtEigenerAI3.styledValue} 
            /> 
        ,
        erfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="erfolgsHonorarI3"
                inputName="erfolgsHonorarI3"
                inputValue={props.data.erfolgsHonorarI3.inputValue}
                label="Aufschlag bei Erfolg"
                outputValue={props.data.erfolgsHonorarI3Value.styledValue}
                type="percent"
            />
        ,
        nichterfolgshonorar: 
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="nichtErfolgsHonorarI3"
                inputName="nichtErfolgsHonorarI3"
                inputValue={props.data.nichtErfolgsHonorarI3.inputValue}
                label="Abzug bei Nicht-Erfolg"
                outputValue={props.data.nichtErfolgsHonorarI3Value.styledValue}
                type="percent"
            />
        ,
        gesamteigenererfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Erfolg"
                value={props.data.gesamtEigenerErfolgI3.styledValue} 
            /> 
        ,
        gesamteigenernichterfolg: 
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Nicht-Erfolg"
                value={props.data.gesamtEigenerNichtErfolgI3.styledValue} 
            /> 
    }

    if (props.mobile) {
        const conditions = {
            mehrvergleich: props.data.mehrvergleich.checked,
            diffvfgebuehr: props.data.mehrvergleich.checked,
            diffeinigungsgebuehr: props.data.mehrvergleich.checked,
            zusatzgebuehr: props.data.gebuehrenOrdnung,
            erfolgshonorar: showErfolgshonorar,
            nichterfolgshonorar: showErfolgshonorar,
            gesamteigenererfolg: showErfolgshonorar,
            gesamteigenernichterfolg: showErfolgshonorar,
            ubvauslagen: props.data.ubv.checked,
            ubvverfahrensgebuehr: props.data.ubv.checked,
            ubvterminsgebuehr: props.data.ubv.checked
        }

        const contentAGProcessed = Object.keys(contentAG).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentAG[key]}
                </div>
            )
        });

        const contentI1Processed = Object.keys(contentI1).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI1[key]}
                </div>
            )
        });

        const contentI2Processed = Object.keys(contentI2).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI2[key]}
                </div>
            )
        });

        const contentI3Processed = Object.keys(contentI3).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI3[key]}
                </div>
            )
        });

        const mobileAccordionData = [
            {
                title: <h2>{contentAG.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isAussergerichtlich ? contentAGProcessed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isAussergerichtlich,
                open: false
            },
            {
                title: <h2>{contentI1.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz1 ? contentI1Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz1,
                open: false
            },
            {
                title: <h2>{contentI2.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz2 ? contentI2Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz2,
                open: false
            },
            {
                title: <h2>{contentI3.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz3 ? contentI3Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz3,
                open: false
            }
        ]

        return (
            <>
                <CalculatorBox display="block">
                    <div className="mobile-row with-checks">
                        <p>Abrechnungsmodell:</p>
                        <RadioButtons
                            data={radioButtons}
                            fontSize="small"
                            handleChange={props.handles.handleChange}
                        />
                        <CheckboxGroup
                            data={checkboxes}
                            fontSize="small"
                            handleChange={props.handles.handleChange}
                        />

                    </div>
                </CalculatorBox>

                <CalculatorBox display="block" background="lightblue" paddingBottom="0px">
                    <Accordion
                        openOnChange={true}
                        data={mobileAccordionData}
                        options={{withBorder: false}}
                    />
                </CalculatorBox>
            </>
        )
    }

    return (
        <>
            <CalculatorBox>
                <CalculatorColumn>
                    <p>Abrechnungsmodell:</p>
                </CalculatorColumn>
                <CalculatorColumn>
                    <RadioButtons
                        data={radioButtons}
                        fontSize="small"
                        handleChange={props.handles.handleChange}
                    />
                </CalculatorColumn>
                <CalculatorColumn flexBasis="48%">
                    <CheckboxGroup
                        data={checkboxes}
                        fontSize="small"
                        handleChange={props.handles.handleChange}
                    />
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue">
                <CalculatorColumn textAlign="center">
                    {isAussergerichtlich ? contentAG.title : null}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {isInstanz1 ? contentI1.title : null}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {isInstanz2 ? contentI2.title : null}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {isInstanz3 ? contentI3.title : null}
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.streitwert : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.streitwert : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.streitwert : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.streitwert : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.mehrvergleich.checked ?
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />

                <CalculatorColumn>
                    {isInstanz1 ? contentI1.mehrvergleich : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.mehrvergleich : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.mehrvergleich : null}
                </CalculatorColumn>
            </CalculatorBox>
            : null}

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.geschaeftsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.verfahrensgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.verfahrensgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.verfahrensgebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.mehrvergleich.checked ?
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.diffvfgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.diffvfgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.diffvfgebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>
            : null}

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.abzuganrechteil : null}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.terminsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.terminsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.terminsgebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.gebuehrenOrdnung ? 
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.zusatzgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.zusatzgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn />
            </CalculatorBox>
            : null }

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.einigungsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.einigungsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.einigungsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.einigungsgebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.mehrvergleich.checked ?
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.diffeinigungsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.diffeinigungsgebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.diffeinigungsgebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>
            : null}

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.profigebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.profigebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.profigebuehr : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.profigebuehr : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.ubv.checked ?
                <>
                <CalculatorBox background="lightblue3">
                    <CalculatorColumn />
                    <CalculatorColumn>
                        {isInstanz1 ? contentI1.ubvverfahrensgebuehr : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz2 ? contentI2.ubvverfahrensgebuehr : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz3 ? contentI3.ubvverfahrensgebuehr : null}
                    </CalculatorColumn>
                </CalculatorBox>

                <CalculatorBox background="lightblue3">
                    <CalculatorColumn />
                    <CalculatorColumn>
                        {isInstanz1 ? contentI1.ubvterminsgebuehr : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz2 ? contentI2.ubvterminsgebuehr : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz3 ? contentI3.ubvterminsgebuehr : null}
                    </CalculatorColumn>
                </CalculatorBox>

                <CalculatorBox background="lightblue3">
                    <CalculatorColumn />
                    <CalculatorColumn>
                        {isInstanz1 ? contentI1.ubvauslagen : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz2 ? contentI2.ubvauslagen : null}
                    </CalculatorColumn>
                    <CalculatorColumn>
                        {isInstanz3 ? contentI3.ubvauslagen : null}
                    </CalculatorColumn>
                </CalculatorBox>
                </>
            : null}

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.auslagen : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.auslagen : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.auslagen : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.auslagen : null}
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.sonstkosten : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.sonstkosten : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.sonstkosten : null}
                </CalculatorColumn>
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.privgutachten : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.privgutachten : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.privgutachten : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.privgutachten : null}
                </CalculatorColumn>
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.ust : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.ust : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.ust : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.ust : null}
                </CalculatorColumn>
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn>
                    {isAussergerichtlich ? contentAG.gesamteigener : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz1 ? contentI1.gesamteigener : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? contentI2.gesamteigener : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? contentI3.gesamteigener : null}
                </CalculatorColumn>
            </CalculatorBox>                        

            {showErfolgshonorar ? 
                <>
                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn>
                            {isAussergerichtlich ? contentAG.erfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz1 ? contentI1.erfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz2 ? contentI2.erfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz3 ? contentI3.erfolgshonorar : null}
                        </CalculatorColumn>
                    </CalculatorBox>            

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn>
                            {isAussergerichtlich ? contentAG.nichterfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz1 ? contentI1.nichterfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz2 ? contentI2.nichterfolgshonorar : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz3 ? contentI3.nichterfolgshonorar : null}
                        </CalculatorColumn>
                    </CalculatorBox>                      

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn>
                            {isAussergerichtlich ? contentAG.gesamteigenererfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz1 ? contentI1.gesamteigenererfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz2 ? contentI2.gesamteigenererfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz3 ? contentI3.gesamteigenererfolg : null}
                        </CalculatorColumn>
                    </CalculatorBox>

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn>
                            {isAussergerichtlich ? contentAG.gesamteigenernichterfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz1 ? contentI1.gesamteigenernichterfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz2 ? contentI2.gesamteigenernichterfolg : null}
                        </CalculatorColumn>
                        <CalculatorColumn>
                            {isInstanz3 ? contentI3.gesamteigenernichterfolg : null}
                        </CalculatorColumn>
                    </CalculatorBox>                    
                </>
            : null}
        </>
    )
}

export function CalculatorSection3aContentHourly(props) {
    const radioButtons = [
        {
            category: 'appState',
            checked: props.data.honorar === 'rvg' ? true : false,
            label: 'RVG',
            name: 'honorar',
            value: 'rvg'
        },
        {
            category: 'appState',
            checked: props.data.honorar === 'stunden' ? true : false,
            label: 'Stundenhonorar',
            name: 'honorar',
            value: 'stunden'
        }
    ]

    const checkboxes = [
        {
            checked: props.data.mehrvergleich.checked,
            label: 'Mehrvergleich',
            name: 'mehrvergleich'
        },
        {
            checked: props.data.erfolgshonorar.checked,
            label: 'Erfolgshonorar',
            name: 'erfolgshonorar'
        },
        {
            checked: props.data.ubv.checked,
            label: 'Mit UBV',
            name: 'ubv'
        }
    ]

    const showErfolgshonorar = props.data.erfolgshonorar.checked

    const contentI1 = {
        hours: 
            <InputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="hourlyHours"
                label="Stundenzahl"
                name="hourlyHours"
                type="number"
                value={props.data.hourlyHours.inputValue}
            />,
        rate:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="hourlyRate"
                label="Stundensatz"
                name="hourlyRate"
                type="euro"
                value={props.data.hourlyRate.inputValue}
            />,
        auslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="auslagenI1"
                label="Auslagen VV7002"
                name="auslagenI1"
                type="euro"
                value={props.data.auslagenI1.inputValue}
            />,
        sonstkosten:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="sonstKostenI1"
                label="Sonst. Kosten VV7000, 7003ff"
                name="sonstKostenI1"
                type="euro"
                value={props.data.sonstKostenI1.inputValue}
            />,
        ust:
            <OutputField
                label="19% Ust."
                value={props.data.Ust19I1.styledValue} 
            />,
        gesamt:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten eigener Anwalt"
                value={props.data.gesamtEigenerAI1.styledValue} 
            />,
        erfolgshonorar:
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="erfolgsHonorarI1"
                inputName="erfolgsHonorarI1"
                inputValue={props.data.erfolgsHonorarI1.inputValue}
                label="Aufschlag bei Erfolg"
                outputValue={props.data.erfolgsHonorarI1Value.styledValue}
                type="percent"
            />,
        nichterfolgshonorar:
            <InputOutputField
                decimals="0"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="nichtErfolgsHonorarI1"
                inputName="nichtErfolgsHonorarI1"
                inputValue={props.data.nichtErfolgsHonorarI1.inputValue}
                label="Abzug bei Nicht-Erfolg"
                outputValue={props.data.nichtErfolgsHonorarI1Value.styledValue}
                type="percent"
            />,
        gesamteigenererfolg:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Erfolg"
                value={props.data.gesamtEigenerErfolgI1.styledValue} 
            />,
        gesamteigenernichterfolg:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten bei Nicht-Erfolg"
                value={props.data.gesamtEigenerNichtErfolgI1.styledValue} 
            /> 
    }

    if (props.mobile) {
        const conditions = {
            erfolgshonorar: showErfolgshonorar,
            nichterfolgshonorar: showErfolgshonorar,
            gesamteigenererfolg: showErfolgshonorar,
            gesamteigenernichterfolg: showErfolgshonorar,
        }

        const contentI1Processed = Object.keys(contentI1).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI1[key]}
                </div>
            )
        });

        return (
            <>
                <CalculatorBox display="block">
                    <div className="mobile-row with-checks">
                        <p>Abrechnungsmodell:</p>
                        <RadioButtons
                            data={radioButtons}
                            fontSize="small"
                            handleChange={props.handles.handleChange}
                        />
                        <CheckboxGroup
                            data={checkboxes}
                            fontSize="small"
                            handleChange={props.handles.handleChange}
                        />

                    </div>
                </CalculatorBox>

                <CalculatorBox display="block" background="lightblue3">
                    {contentI1Processed}
                </CalculatorBox>
            </>
        )
    }

    return (
        <>
            <CalculatorBox>
                <CalculatorColumn>
                    <p>Abrechnungsmodell:</p>
                </CalculatorColumn>
                <CalculatorColumn>
                    <RadioButtons
                        data={radioButtons}
                        fontSize="small"
                        handleChange={props.handles.handleChange}
                    />
                </CalculatorColumn>
                <CalculatorColumn flexBasis="48%">
                    <CheckboxGroup
                        data={checkboxes}
                        fontSize="small"
                        handleChange={props.handles.handleChange}
                    />
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.hours}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.rate}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.auslagen}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.sonstkosten}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.ust}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {contentI1.gesamt}
                </CalculatorColumn>
                <CalculatorColumn />
                <CalculatorColumn />
            </CalculatorBox>            

            {showErfolgshonorar ? 
                <>
                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn />
                        <CalculatorColumn>
                            {contentI1.erfolgshonorar}
                        </CalculatorColumn>
                        <CalculatorColumn />
                        <CalculatorColumn />
                    </CalculatorBox>            

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn />
                        <CalculatorColumn>
                            {contentI1.nichterfolgshonorar}
                        </CalculatorColumn>
                        <CalculatorColumn />
                        <CalculatorColumn />
                    </CalculatorBox>                      

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn />
                        <CalculatorColumn>
                            {contentI1.gesamteigenererfolg}
                        </CalculatorColumn>
                        <CalculatorColumn />
                        <CalculatorColumn />
                    </CalculatorBox>

                    <CalculatorBox background="lightblue3">
                        <CalculatorColumn />
                        <CalculatorColumn>
                            {contentI1.gesamteigenernichterfolg}
                        </CalculatorColumn>
                        <CalculatorColumn />
                        <CalculatorColumn />
                    </CalculatorBox>                    
                </>
            : null}            

        </>
    )
}