import {getEditValueFromCalcValue, getStyledValue} from '../Helpers/valueHelpers'

function calcAuslagen() {
    const instances = ['AG', 'I1', 'I2', 'I3']

    let newState = {}
    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    instances.forEach((instance) => {
        if (instance === 'AG') {
            return
        }

        let auslagen = 20

        auslagen = auslagen * this.state.anzahlGegner.calcValue

        newState['gaAuslagen' + instance] = {
            inputValue: getStyledValue(auslagen, 'euro', 2),
            calcValue: auslagen,
            editValue: getEditValueFromCalcValue(auslagen),
            styledValue: getStyledValue(auslagen, 'euro', 2),
        }
        value.gegnerAnwalt[instance] = auslagen
    })

    return {
        value,
        newState
    }
}

export default calcAuslagen