import React from 'react'

import Checkbox from './Checkbox'

const CheckboxGroup = (props) => {
    const checkboxes = props.data.map((radio, key) => (
        <Checkbox 
            checked={radio.checked}
            handleChange={props.handleChange}
            key={key} 
            label={radio.label}
            name={radio.name}
        />
    ))

    const className = 'form-field__checkbox-group ' + props.fontSize

    return (
        <div className={className}>
            {checkboxes}
        </div>
    )
}

export default CheckboxGroup