import React from 'react'

import svgDrucker from '../../svg/Drucker.svg'
import svgPDF from '../../svg/PDF.svg'
import svgZinsrechner from '../../svg/Zinsrechner.svg'
import svgAnfrage from '../../svg/RPFAnfrage.svg'


const Footer = () => {
    return(
        <footer className="footer">
            <div className="button-row">
                <a href="#zr" className="button">
                    Finanzierungsanfrage
                    <img src={svgAnfrage} alt="Icon Zinsrechner" width="24" height="24" className="button__svg" />
                </a>
                <a href="#zr" className="button">
                    Drucken
                    <img src={svgDrucker} alt="Icon Drucker" width="30" height="30" className="button__svg" />
                </a>
            </div>
        </footer>
    )
}

export default Footer