import React from 'react'

class Checkbox extends React.Component {
    onClick(event) {
        event.stopPropagation()
    }

    render() {
        let classes = ['form-field', 'form-field--checkbox']
        classes = classes.concat(this.props.classes)

        const style = {
            opacity: this.props.opacity
        }

        const value = this.props.value ? this.props.value : 1

        return(
            <div className={classes.join(' ')} style={style}>
                <label className="form-field__label form-field__label--checkbox form-field__checkbox" onClick={this.onClick}>
                    <input 
                        checked={this.props.checked}
                        className="form-field__native-checkbox" 
                        name={this.props.name}
                        value={value}
                        onChange={
                            event => {
                                this.props.handleChange(event)
                            }
                        }
                        type="checkbox" 
                    />
                    <span className="form-field__checkbox-icon"></span>
                    <span className="form-field__checkbox-text">{this.props.label}</span>
                </label>
            </div>
        )
    }
}

export default Checkbox