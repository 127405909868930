import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import Checkbox from '../InputFields/Checkbox'
import OutputField from '../OutputFields/OutputField'
import InputField from '../InputFields/InputField'
import InputOutputField from '../InputFields/InputOutputField'
import OutputFieldInline from '../OutputFields/OutputFieldInline'

import Accordion from '../../Accordion/Accordion'

import svgGegnerAnwalt from '../../../svg/gegnerAnwalt.svg'

export function CalculatorSection3bTitle(props) {
    return (
        <>
            <h2 className="no-caps">Gegnerische Anwälte</h2>
            <div className="accordion__trigger-column">
                <Checkbox 
                    checked={props.data.gesamtkostenFremdAnwalt.checked}
                    label="Gesamtkosten"
                    handleChange={props.handles.handleChange}
                    name="gesamtkostenFremdAnwalt"
                />
                <OutputFieldInline 
                    value={props.data.gesamtkostenFremdAnwaltValue.styledValue} 
                />
            </div>
        </>
    )
}

export function CalculatorSection3bContent(props) {
    const isInstanz1 = props.data.stufeI1.checked
    const isInstanz2 = props.data.stufeI2.checked
    const isInstanz3 = props.data.stufeI3.checked

    const contentI1 = {
        title: <p>1. Instanz</p>,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaVerfahrensGebuehrvv3100I1"
                inputName="gaVerfahrensGebuehrvv3100I1"
                inputValue={props.data.gaVerfahrensGebuehrvv3100I1.inputValue}
                label="Verfahrensgebühr VV3100, 1008"
                outputValue={props.data.gaVerfahrensGebuehrvv3100I1Value.styledValue}
                type="number"
            />,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Mehrwertbetrag"
                value={props.data.gaDiffVFGebuehrI1.styledValue}
            />,
        terminsgebuehr:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaTerminsGebuehrI1"
                inputName="gaTerminsGebuehrI1"
                inputValue={props.data.gaTerminsGebuehrI1.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.gaTerminsGebuehrI1Value.styledValue}
                type="number"
            />,
        zusatzgebuehr: 
            <OutputField
                label="0,3 Zusatzgebühr VV1010"
                value={props.data.gaZusatzGebuehrVV1010I1Value.styledValue}
            />,
        einigungsgebuehr: 
            <OutputField 
                label="Einigungsgebühr VV1003f"
                value={props.data.gaEinigungsGebuehrI1Value.styledValue}
            />,
        diffeinigungsgebuehr:
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.gaDiffEinigungsGebuehrI1.styledValue}
            />,
        auslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaAuslagenI1"
                label="Auslagen VV7002"
                name="gaAuslagenI1"
                type="euro"
                value={props.data.gaAuslagenI1.inputValue}
            />,
        sonstkosten:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaSonstKostenI1"
                label="Sonst. Kosten VV7000, 7003ff"
                name="gaSonstKostenI1"
                type="euro"
                value={props.data.gaSonstKostenI1.inputValue}
            />,
        ust:
            <OutputField
                label="19% Ust."
                value={props.data.gaUst19I1.styledValue} 
            />,
        gesamt:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten gegner. Anwälte"
                value={props.data.gesamtFremdAI1.styledValue} 
            /> 
    }

    const contentI2 = {
        title: <p>2. Instanz</p>,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaVerfahrensGebuehrvv3100I2"
                inputName="gaVerfahrensGebuehrvv3100I2"
                inputValue={props.data.gaVerfahrensGebuehrvv3100I2.inputValue}
                label="Verfahrensgebühr VV3100, 1008"
                outputValue={props.data.gaVerfahrensGebuehrvv3100I2Value.styledValue}
                type="number"
            />,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Mehrwertbetrag"
                value={props.data.gaDiffVFGebuehrI2.styledValue}
            />,
        terminsgebuehr:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaTerminsGebuehrI2"
                inputName="gaTerminsGebuehrI2"
                inputValue={props.data.gaTerminsGebuehrI2.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.gaTerminsGebuehrI2Value.styledValue}
                type="number"
            />,
        zusatzgebuehr: 
            <OutputField
                label="0,3 Zusatzgebühr VV1010"
                value={props.data.gaZusatzGebuehrVV1010I2Value.styledValue}
            />,
        einigungsgebuehr: 
            <OutputField 
                label="Einigungsgebühr VV1003f"
                value={props.data.gaEinigungsGebuehrI2Value.styledValue}
            />,
        diffeinigungsgebuehr:
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.gaDiffEinigungsGebuehrI2.styledValue}
            />,
        auslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaAuslagenI2"
                label="Auslagen VV7002"
                name="gaAuslagenI2"
                type="euro"
                value={props.data.gaAuslagenI2.inputValue}
            />,
        sonstkosten:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaSonstKostenI2"
                label="Sonst. Kosten VV7000, 7003ff"
                name="gaSonstKostenI2"
                type="euro"
                value={props.data.gaSonstKostenI2.inputValue}
            />,
        ust:
            <OutputField
                label="19% Ust."
                value={props.data.gaUst19I2.styledValue} 
            />,
        gesamt:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten gegner. Anwälte"
                value={props.data.gesamtFremdAI2.styledValue} 
            />
    }

    const contentI3 = {
        title: <p>3. Instanz</p>,
        verfahrensgebuehr: 
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaVerfahrensGebuehrvv3100I3"
                inputName="gaVerfahrensGebuehrvv3100I3"
                inputValue={props.data.gaVerfahrensGebuehrvv3100I3.inputValue}
                label="Verfahrensgebühr VV3100, 1008"
                outputValue={props.data.gaVerfahrensGebuehrvv3100I3Value.styledValue}
                type="number"
            />,
        diffvfgebuehr: 
            <OutputField
                label="Differenz Mehrwertbetrag"
                value={props.data.gaDiffVFGebuehrI3.styledValue}
            />,
        terminsgebuehr:
            <InputOutputField
                decimals="1"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                inputId="gaTerminsGebuehrI3"
                inputName="gaTerminsGebuehrI3"
                inputValue={props.data.gaTerminsGebuehrI3.inputValue}
                label="Terminsgebühr VV3104"
                outputValue={props.data.gaTerminsGebuehrI3Value.styledValue}
                type="number"
            />,
        einigungsgebuehr: 
            <OutputField 
                label="Einigungsgebühr VV1003f"
                value={props.data.gaEinigungsGebuehrI3Value.styledValue}
            />,
        diffeinigungsgebuehr:
            <OutputField
                label="Differenz Einigungsgebühr"
                value={props.data.gaDiffEinigungsGebuehrI3.styledValue}
            />,
        auslagen:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaAuslagenI3"
                label="Auslagen VV7002"
                name="gaAuslagenI3"
                type="euro"
                value={props.data.gaAuslagenI3.inputValue}
            />,
        sonstkosten:
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="gaSonstKostenI3"
                label="Sonst. Kosten VV7000, 7003ff"
                name="gaSonstKostenI3"
                type="euro"
                value={props.data.gaSonstKostenI3.inputValue}
            />,
        ust:
            <OutputField
                label="19% Ust."
                value={props.data.gaUst19I3.styledValue} 
            />,
        gesamt:
            <OutputField
                backgroundColor="#061429"
                bold={true}
                label="Gesamtkosten gegner. Anwälte"
                value={props.data.gesamtFremdAI3.styledValue} 
            />
    }

    if (props.mobile) {
        const conditions = {
            diffvfgebuehr: props.data.mehrvergleich.checked,
            diffeinigungsgebuehr: props.data.mehrvergleich.checked
        }


        const contentI1Processed = Object.keys(contentI1).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI1[key]}
                </div>
            )
        });

        const contentI2Processed = Object.keys(contentI2).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI2[key]}
                </div>
            )
        });

        const contentI3Processed = Object.keys(contentI3).map(key => {
            if (key === 'title' || (typeof conditions[key] !== 'undefined' && conditions[key] === false )) {
                return null
            }

            return (
                <div className="mobile-row" key={key}>
                    {contentI3[key]}
                </div>
            )
        });

        const mobileAccordionData = [
            {
                title: <h2>{contentI1.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz1 ? contentI1Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz1,
                open: false
            },
            {
                title: <h2>{contentI2.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz2 ? contentI2Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz2,
                open: false
            },
            {
                title: <h2>{contentI3.title}</h2>,
                content: (
                    <>
                        <CalculatorBox display="block" background="lightblue3">
                            {isInstanz3 ? contentI3Processed : ''}
                        </CalculatorBox>
                    </>
                ),
                indicator: isInstanz3,
                open: false
            }
        ]

        return (
            <>
                <CalculatorBox display="block" background="lightblue" paddingBottom="0px">
                    <Accordion
                        openOnChange={true}
                        data={mobileAccordionData}
                        options={{withBorder: false}}
                    />
                </CalculatorBox>
            </>
        )
    }

    return (
        <>
            <CalculatorBox background="lightblue">
                <CalculatorColumn />
                <CalculatorColumn textAlign="center">
                    {contentI1.title}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {contentI2.title}
                </CalculatorColumn>
                <CalculatorColumn textAlign="center">
                    {contentI3.title}
                </CalculatorColumn>
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.verfahrensgebuehr 
                    : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.verfahrensgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.verfahrensgebuehr
                    : null}
                </CalculatorColumn>
            </CalculatorBox>            

            {props.data.mehrvergleich.checked ?
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.diffvfgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.diffvfgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.diffvfgebuehr
                    : null}
                </CalculatorColumn>
            </CalculatorBox>            
            : null}

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.terminsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.terminsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.terminsgebuehr
                    : null}
                </CalculatorColumn>
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.zusatzgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.zusatzgebuehr
                    : null}
                </CalculatorColumn>
                <CalculatorColumn />
            </CalculatorBox>

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.einigungsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.einigungsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.einigungsgebuehr
                    : null}
                </CalculatorColumn>
            </CalculatorBox>

            {props.data.mehrvergleich.checked ?
            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.diffeinigungsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.diffeinigungsgebuehr
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.diffeinigungsgebuehr
                    : null}
                </CalculatorColumn>
            </CalculatorBox>            
            : null}            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.auslagen
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.auslagen
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.auslagen
                    : null}
                </CalculatorColumn>
            </CalculatorBox>            

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.sonstkosten
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.sonstkosten
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.sonstkosten
                    : null}
                </CalculatorColumn>
            </CalculatorBox>                        

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.ust
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.ust
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.ust
                    : null}
                </CalculatorColumn>
            </CalculatorBox>                    

            <CalculatorBox background="lightblue3">
                <CalculatorColumn />
                <CalculatorColumn>
                    {isInstanz1 ? 
                        contentI1.gesamt
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz2 ? 
                        contentI2.gesamt
                        : null}
                </CalculatorColumn>
                <CalculatorColumn>
                    {isInstanz3 ? 
                        contentI3.gesamt
                    : null}
                </CalculatorColumn>
            </CalculatorBox>                                    
        </>
    )
}

