import React from 'react'

import RadioButton from './RadioButton'

const RadioButtons = (props) => {
    const radioButtons = props.data.map((radio, key) => (
        <RadioButton 
            category={radio.category}
            checked={radio.checked}
            handleChange={props.handleChange}
            key={key} 
            label={radio.label}
            name={radio.name}
            value={radio.value}
        />
    ))

    const className = 'form-field__radio-buttons ' + props.fontSize

    return (
        <div className={className}>
            {radioButtons}
        </div>
    )
}

export default RadioButtons