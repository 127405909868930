import React from 'react'
import logo from '../../img/omni-bridgeway-logo.png'

import Navigation from './Navigation'

const Header = () => {
    return(
        <header className="header">
            <div className="inside">
                <div className="header__logo">
                    <a href="https://www.omnibridgeway.de/" target="_blank" rel="noopener noreferrer"><img src={logo} className="header__logo-image" alt="Omni Bridgeway" /></a>
                </div>
                <Navigation />
            </div>
        </header>
    )
}

export default Header