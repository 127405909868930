import React from 'react'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'

import Checkbox from '../InputFields/Checkbox'
import OutputField from '../OutputFields/OutputField'



const CalculatorSection4 = (props) => {
    const sum1 = (
        <>
            <Checkbox 
                classes={['label-bold']}
                checked={props.data.stufeAG.checked}
                handleChange={props.handles.handleChange}
                label="Summe Aussergerichtlich"
                name="stufeAG"
            />
            <OutputField 
                margin="10px 0 20px"
                value={props.data.summeAussergerichtlich.styledValue} 
            />
        </>
    )

    const sum2 = (
        <>
            <Checkbox 
                classes={['label-bold']}
                checked={props.data.stufeI1.checked}
                handleChange={props.handles.handleChange}
                label="Summe 1. Instanz"
                name="stufeI1"
            />
            <OutputField 
                margin="10px 0 20px"
                value={props.data.summeI1.styledValue} 
            />
        </>
    )

    const sum3 = (
        <>
            <Checkbox 
                classes={['label-bold']}
                checked={props.data.stufeI2.checked}
                handleChange={props.handles.handleChange}
                label="Summe 2. Instanz"
                name="stufeI2"
            />
            <OutputField 
                margin="10px 0 20px"
                value={props.data.summeI2.styledValue} 
            />
        </>
    )

    const sum4 = (
        <>
            <Checkbox 
                classes={['label-bold']}
                checked={props.data.stufeI3.checked}
                handleChange={props.handles.handleChange}
                label="Summe 3. Instanz"
                name="stufeI3"
            />
            <OutputField 
                margin="10px 0 20px"
                value={props.data.summeI3.styledValue} 
            />
        </>
    )

    const sum5 = (
        <>
            <h2 className="no-caps" style={{color: '#2B809E'}}>Gesamtprozesskosten</h2>
            <OutputField 
                backgroundColor="#A67F48"
                margin="0 0 20px"
                value={props.data.gesamtprozesskosten.styledValue} 
            />
        </>
    )

    if (props.mobile) {
        return (
            <CalculatorBox display="block" background="white" flexWrap="wrap" paddingBottom="10px">
                <div className="mobile-row">
                    {sum1}
                </div>

                <div className="mobile-row">
                    {sum2}
                </div>

                <div className="mobile-row">
                    {sum3}
                </div>

                <div className="mobile-row">
                    {sum4}
                </div>

                <div className="mobile-row">
                    {sum5}
                </div>
            </CalculatorBox>
        )
    }

    return (
        <CalculatorBox background="white" flexWrap="wrap" paddingBottom="10px">
            <CalculatorColumn>
                {sum1}
            </CalculatorColumn>
            <CalculatorColumn>
                {sum2}
            </CalculatorColumn>
            <CalculatorColumn>
                {sum3}
            </CalculatorColumn>
            <CalculatorColumn>                
                {sum4}
                {sum5}
            </CalculatorColumn>
        </CalculatorBox>
    )
}

export default CalculatorSection4