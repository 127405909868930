import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcRechtsGebuehr() {
    const instances = [null, 'I1', 'I2', 'I3']

    let newState = {}

    instances.forEach((instance) => {
        instance = instance !== null ? instance : ''

        const streitwert = this.state['streitwert' + instance].calcValue
        const value = calcGebuehr(streitwert, this.state.gebuehrenOrdnung)

        let valueGedeckelt = value;
        if (streitwert > 1000000) {
            valueGedeckelt = calcGebuehr(1000000, this.state.gebuehrenOrdnung)
        }

        newState = {...newState, 
            ['geb13Rvg' + instance]: {
                calcValue: value.rvg,
                styledValue: getStyledValue(value.rvg, 'euro', 2),
            },
            ['geb13RvgGedeckelt' + instance]: {
                calcValue: valueGedeckelt.rvg,
                styledValue: getStyledValue(valueGedeckelt.rvg, 'euro', 2),
            },
            ['geb34Gkg' + instance]: {
                calcValue: value.gkg,
                styledValue: getStyledValue(value.gkg, 'euro', 2),
            }
        }
    })

    return {
        value: {
            eigenerAnwalt: {},
            gegnerAnwalt: {},
            gericht: {}
        },
        newState
    }
}

export default calcRechtsGebuehr