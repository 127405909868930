import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcTerminsGebuehr() {
    const instances = ['I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let terminsGebuehr = 0
        let terminsGebuehrGegner = 0

        terminsGebuehr = this.state['terminsGebuehr' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue
        terminsGebuehrGegner = this.state['gaTerminsGebuehr' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue

        if (this.state.mehrvergleich.checked) {
            const sumMehrwertbetragStreitwert = this.state['mehrvergleich' + instance].calcValue + this.state['streitwert' + instance].calcValue
            terminsGebuehr = calcGebuehr(sumMehrwertbetragStreitwert, this.state.gebuehrenOrdnung).rvg * this.state['terminsGebuehr' + instance].calcValue
            terminsGebuehrGegner = calcGebuehr(sumMehrwertbetragStreitwert, this.state.gebuehrenOrdnung).rvg * this.state['gaTerminsGebuehr' + instance].calcValue
        }

        value.eigenerAnwalt[instance] = terminsGebuehr
        value.gegnerAnwalt[instance] = terminsGebuehrGegner

        newState = {
            ...newState,
            ['terminsGebuehr' + instance + 'Value']: {
                calcValue: terminsGebuehr,
                styledValue: getStyledValue(terminsGebuehr, 'euro', 2),
            },
            ['gaTerminsGebuehr' + instance + 'Value']: {
                calcValue: terminsGebuehrGegner,
                styledValue: getStyledValue(terminsGebuehrGegner, 'euro', 2),
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcTerminsGebuehr