import {getStyledValue} from '../Helpers/valueHelpers'

function calcVerfahrensGebuehr() {
    const instances = ['AG', 'I1', 'I2', 'I3']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        if (instance === 'AG') {
            let geschaeftsgebuehr = 0

            geschaeftsgebuehr = this.state.geschaeftsGebuehrvv2300.calcValue * this.state.geb13Rvg.calcValue

            newState = {
                ...newState,
                geschaeftsGebuehrvv2300Value: {
                    calcValue: geschaeftsgebuehr,
                    styledValue: getStyledValue(geschaeftsgebuehr, 'euro', 2),
                }                
            }

            value.eigenerAnwalt.AG = geschaeftsgebuehr

            return
        }

        let verfahrensGebuehr = 0
        let verfahrensGebuehrGegner = 0

        verfahrensGebuehr = this.state['verfahrensGebuehrvv3100' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue
        verfahrensGebuehrGegner = this.state['gaVerfahrensGebuehrvv3100' + instance].calcValue * this.state['geb13Rvg' + instance].calcValue

        newState = {
            ...newState,
            ['verfahrensGebuehrvv3100' + instance + 'Value']: {
                calcValue: verfahrensGebuehr,
                styledValue: getStyledValue(verfahrensGebuehr, 'euro', 2),
            },
            ['gaVerfahrensGebuehrvv3100' + instance + 'Value']: {
                calcValue: verfahrensGebuehrGegner,
                styledValue: getStyledValue(verfahrensGebuehrGegner, 'euro', 2),
            }
        }

        value.eigenerAnwalt[instance] = verfahrensGebuehr
        value.gegnerAnwalt[instance] = verfahrensGebuehrGegner
    })
    

    return {
        value,
        newState
    }
}

export default calcVerfahrensGebuehr