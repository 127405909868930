import React from 'react'

const Hamburger = (props) => {
    let classes = ['hamburger']

    if (props.active) {
        classes.push('hamburger--active')
    }

    return (
        <a href="#menu" className={classes.join(' ')} onClick={props.clickHandler}>
            <span className="hamburger__bar"></span>
            <span className="hamburger__bar"></span>
            <span className="hamburger__bar"></span>
        </a>
    )
}

export default Hamburger