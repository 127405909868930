import React from 'react'
import Select from 'react-select'

import CalculatorBox from '../Box/CalculatorBox'
import CalculatorColumn from '../Column/CalculatorColumn'
import CalculatorColumnBottom from '../Column/CalculatorColumnBottom'
import CalculatorColumnBottomLeft from '../Column/CalculatorColumnBottomLeft'
import CalculatorColumnBottomRight from '../Column/CalculatorColumnBottomRight'

import FinanzierungsanfrageButton from '../../StaticComponents/FinanzierungsanfrageButton'

import InputField from '../InputFields/InputField'
import Checkbox from '../InputFields/Checkbox'
import OutputField from '../OutputFields/OutputField'

const CalculatorSection1 = (props) => {
        const allgemeineAngaben = (
        <>
            <h2>Allgemeine Angaben</h2>
            <InputField
                decimals="2"
                handleInput={props.handles.handleInput}
                handleFocus={props.handles.handleFocus}
                handleBlur={props.handles.handleBlur}
                id="streitwert"
                label="Streitwert"
                labelPosition="inside"
                name="streitwert"
                textAlign="left"
                type="euro"
                value={props.data.streitwert.inputValue}
            />
        </>
    )

    const sieSind = (
        <>
            <h2 className="no-caps">Sie sind:</h2>
            <Select
                className="form-field__select-container"
                classNamePrefix="form-field__select"
                options={[
                    {
                        label: 'Mandant',
                        value: 'mandant'
                    },
                    {
                        label: 'Rechtsanwalt',
                        value: 'rechtsanwalt'
                    }
                ]}
                placeholder="Bitte wählen"
            />
        </>
    )

    const mandantenBox = (
        <CalculatorColumnBottom>
            <CalculatorColumnBottomLeft>
                <InputField
                    decimals="0"
                    form="quad"
                    handleInput={props.handles.handleInput}
                    handleFocus={props.handles.handleFocus}
                    handleBlur={props.handles.handleBlur}
                    id="anzahlMandanten"
                    label="Anzahl"
                    labelPosition="inside"
                    min="1"
                    name="anzahlMandanten"
                    textAlign="center"
                    type="number"
                    value={props.data.anzahlMandanten.inputValue}
                />
            </CalculatorColumnBottomLeft>

            <CalculatorColumnBottomRight>
                <h3>Mandanten</h3>
                <Checkbox
                    checked={props.data.mandantenUst.checked}
                    label="+ 19% Ust."
                    handleChange={props.handles.handleChange}
                    name="mandantenUst"
                />
                <Checkbox
                    classes={['form-field--gem-angelegenheit']}
                    checked={props.data.gemAngelegenheit.checked}
                    label="gem. Angelegenheit"
                    handleChange={props.handles.handleChange}
                    name="gemAngelegenheit"
                    opacity={props.data.showGemAngelegenheit ? 1 : 0}
                />
            </CalculatorColumnBottomRight>
        </CalculatorColumnBottom>
    )

    const gegnerBox = (
        <CalculatorColumnBottom>
            <CalculatorColumnBottomLeft>
                <InputField
                    decimals="0"
                    form="quad"
                    handleInput={props.handles.handleInput}
                    handleFocus={props.handles.handleFocus}
                    handleBlur={props.handles.handleBlur}
                    id="anzahlGegner"
                    label="Anzahl"
                    labelPosition="inside"
                    min="1"
                    name="anzahlGegner"
                    textAlign="center"
                    type="number"
                    value={props.data.anzahlGegner.inputValue}
                />
            </CalculatorColumnBottomLeft>

            <CalculatorColumnBottomRight>
                <h3>Gegner</h3>
                <Checkbox
                    checked={props.data.gegnerUst.checked}
                    label="+ 19% Ust."
                    handleChange={props.handles.handleChange}
                    name="gegnerUst"
                />
            </CalculatorColumnBottomRight>
        </CalculatorColumnBottom>
    )

    const gegnerAnwaelteBox = (
        <CalculatorColumnBottom>
            <CalculatorColumnBottomLeft>
                <InputField
                    decimals="0"
                    form="quad"
                    handleInput={props.handles.handleInput}
                    handleFocus={props.handles.handleFocus}
                    handleBlur={props.handles.handleBlur}
                    id="anzahlGegner"
                    label="Anzahl"
                    labelPosition="inside"
                    min="1"
                    name="anzahlGegnerRA"
                    textAlign="center"
                    type="number"
                    value={props.data.anzahlGegnerRA.inputValue}
                />
            </CalculatorColumnBottomLeft>

            <CalculatorColumnBottomRight>
                <h3>Gegnerische<br />Anwälte</h3>
            </CalculatorColumnBottomRight>
        </CalculatorColumnBottom>
    )

    const gesamtprozesskostenBox = (
        <>
            <h2 className="no-caps">Gesamtprozesskosten</h2>
            <OutputField 
                backgroundColor="#A67F48"
                height="54px"
                padding="18px 10px 17px"
                value={props.data.gesamtprozesskosten.styledValue} 
            />
        </>
    )

    const finanzierungsanfrageButtonBox = (
        <FinanzierungsanfrageButton 
            streitwert={props.data.streitwert}
        />
    )

    if (props.mobile) {
        return (
            <CalculatorBox display="block" paddingBottom={props.data.showGemAngelegenheit ? 50 : 20} stayOnTop={false}>
                <div className="mobile-row">
                    {allgemeineAngaben}
                </div>

                <div className="mobile-row">
                    {sieSind}
                </div>

                <div className="mobile-row flex-50">
                    {mandantenBox}
                    {gegnerBox}
                    {gegnerAnwaelteBox}
                </div>

                <div className="mobile-row">
                    {gesamtprozesskostenBox}
                </div>

                <div className="mobile-row">
                    {finanzierungsanfrageButtonBox}
                </div>
            </CalculatorBox>
        )
    }

    return (
        <CalculatorBox alignItems="flex-end" paddingBottom={props.data.showGemAngelegenheit ? 50 : 20} stayOnTop={true}>
            <CalculatorColumn>
                {allgemeineAngaben}                
                {mandantenBox}
            </CalculatorColumn>

            <CalculatorColumn>
                {gegnerBox}
            </CalculatorColumn>

            <CalculatorColumn>
                {gegnerAnwaelteBox}
            </CalculatorColumn>                        

            <CalculatorColumn alignSelf="flex-start">
                <CalculatorColumnBottomLeft>
                    {gesamtprozesskostenBox}
                </CalculatorColumnBottomLeft>

                <CalculatorColumnBottomRight>
                    {finanzierungsanfrageButtonBox}
                </CalculatorColumnBottomRight>
            </CalculatorColumn>                   
        </CalculatorBox>        
    )
}

export default CalculatorSection1