import faktorenRvg from '../data/faktorenRvg'
import {getEditValueFromCalcValue, getStyledValue} from '../Helpers/valueHelpers'

function calcGegnerTerminsGebuehrFaktor() {
    const anzahlGegner = this.state.anzahlGegner.calcValue
    const anzahlGegnerRA = this.state.anzahlGegnerRA.calcValue
    const instances = ['I1', 'I2', 'I3']

    let newState = {}

    instances.forEach((instance) => {
        const fieldName = 'gaTerminsGebuehr' + instance
        let faktor = 0
        let anzahlGesamt = 0

        if ((anzahlGegner === 1 && anzahlGegnerRA === 1) || (anzahlGegner === 1 && anzahlGegnerRA > 1) || (anzahlGegner > 1 && anzahlGegnerRA === 1)) {
            faktor = 1.2
        }
        else if (anzahlGegner > 1 && anzahlGegnerRA > 1 && anzahlGegner <= anzahlGegnerRA) {
            anzahlGesamt = anzahlGegner > 20 ? 20 : anzahlGegner
            faktor = faktorenRvg.gegnerRaTg[instance.toLowerCase()][anzahlGesamt]
        }
        else {
            anzahlGesamt = anzahlGegnerRA > 20 ? 20 : anzahlGegnerRA
            faktor = faktorenRvg.gegnerRaTg[instance.toLowerCase()][anzahlGesamt]
        }

        newState = {
            ...newState,
            [fieldName]: {
                inputValue: getStyledValue(faktor, 'number', 1),
                calcValue: faktor,
                editValue: getEditValueFromCalcValue(faktor),
                styledValue: getStyledValue(faktor, 'number', 1),
            }
        }
    })

    return {
        value: {
            eigenerAnwalt: {},
            gegnerAnwalt: {},
            gericht: {}
        },
        newState
    }
}

export default calcGegnerTerminsGebuehrFaktor
