/*
 Definitionen des RVG,
 Basisgebuehr: Gebuehr bei 0 Euro Streitwert
 Mindestgebuehr: Sollte die Gebuehr unter diesem Wert liegen, wird auf diesen Angehoben
 Gebuehrentabelle: Die einzelnen der Stufen werden jeweils durch einen Array abgebildet.
 1. Eintrag "Gegenstandswert bis ct
 2. Eintrag "Je weitere ct"
 3. "Erhoehen um ct"
*/

const gebuehrenTabelle = {
    rvg: {
        '2013': {
          basis: 5,
          deckel: 0,
          table: [
            [0, 300, 20],
            [1500, 500, 28],
            [5000, 1000, 37],
            [10000, 3000, 40],
            [25000, 5000, 72],
            [50000, 15000, 77],
            [200000, 30000, 118],
            [500000, 50000, 150],
          ],    
        },
        '2020': {
          basis: 10,
          deckel: 0,
          table: [
            [0, 500, 35],
            [2000, 1000, 51],
            [10000, 3000, 46],
            [25000, 5000, 75],
            [50000, 15000, 85],
            [200000, 30000, 120],
            [500000, 50000, 150],
          ],    
        },
        '2021': {
          basis: 10,
          deckel: 0,
          table: [
            [0, 500, 39],
            [2000, 1000, 56],
            [10000, 3000, 52],
            [25000, 5000, 81],
            [50000, 15000, 94],
            [200000, 30000, 132],
            [500000, 50000, 165],
          ],    
        },
      },
      gkg: {
        '2013': {
          basis: 15,
          deckel: 30000000,
          table: [
            [0, 300, 10],
            [1500, 500, 8],
            [5000, 1000, 15],
            [10000, 3000, 23],
            [25000, 5000, 29],
            [50000, 15000, 100],
            [200000, 30000, 150],
            [500000, 50000, 150],
          ]      
        },
        '2020': {
          basis: 17,
          deckel: 30000000,
          table: [
            [0, 500, 18],
            [2000, 1000, 19],
            [10000, 3000, 26],
            [25000, 5000, 35],
            [50000, 15000, 120],
            [200000, 30000, 179],
            [500000, 50000, 180],
          ]      
        },
        '2021': {
          basis: 18,
          deckel: 30000000,
          table: [
            [0, 500, 20],
            [2000, 1000, 21],
            [10000, 3000, 29],
            [25000, 5000, 38],
            [50000, 15000, 132],
            [200000, 30000, 198],
            [500000, 50000, 198],
          ]      
        },
      },      
}

export default gebuehrenTabelle