import React from 'react'

class InputField extends React.Component {
    componentDidUpdate() {
        if (this.props.value.toString() === '0') {
            this.refs[this.props.name].select()
        }
    }

    render() {
        const textAlign = this.props.textAlign ? this.props.textAlign : 'right'
        const style = {
            textAlign
        }

        let formFieldClass = [
            'form-field'
        ]

        if (this.props.labelPosition === 'inside') {
            formFieldClass.push('label-inside')
        }

        if (this.props.form === 'quad') {
            formFieldClass.push('quad')
        }

        const label = this.props.label ? <span className="form-field__label-text">{this.props.label}</span> : null;

        return (
            <div className={formFieldClass.join(' ')}>
                <label className="form-field__label">
                    {label}
                    <input 
                        className='form-field__input'
                        id={this.props.id}
                        ref={this.props.name}
                        type="text" 
                        name={this.props.name}
                        value={this.props.value} 
                        onChange={
                            event => {
                                this.props.handleInput(event, this.props.type, this.props.decimals)
                            }
                        }
                        onFocus={this.props.handleFocus}
                        onBlur={this.props.handleBlur}
                        style={style}
                        data-min={this.props.min}
                        data-max={this.props.max}
                    />
                </label>
            </div>
        )
    }
}

export default InputField