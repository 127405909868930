import React from 'react'
import CalculatorForm from '../CalculatorForm/CalculatorForm'
import defaultState from '../../data/defaultState'
import {getEditValue, getCalcValue, getStyledValue, getCalcValueFromInput, getInputValue, getEditValueFromCalcValue} from '../../Helpers/valueHelpers'

import calcRechtsGebuehr from '../../calculation/rechtsGebuehr'
import calcVerfahrensGebuehrFaktor from '../../calculation/verfahrensGebuehrFaktor'
import calcVerfahrensGebuehr from '../../calculation/verfahrensGebuehr'
import calcUbvVerfahrensGebuehr from '../../calculation/ubvVerfahrensGebuehr'
import calcAnrechenbarerTeil from '../../calculation/anrechenbarerTeil'
import calcTerminsGebuehr from '../../calculation/terminsGebuehr'
import calcUbvTerminsGebuehr from '../../calculation/ubvTerminsGebuehr'
import calcZusatzGebuehr from '../../calculation/zusatzGebuehr'
import calcEinigungsGebuehr from '../../calculation/einigungsGebuehr'
import calcAuslagenGegner from '../../calculation/auslagenGegner'
import calcProfiGebuehr from '../../calculation/profiGebuehr'
import calcDifferenzVerfahrensgebuer from '../../calculation/differenzVerfahrensgebuer'
import calcDifferenzEinigungsgebuehr from '../../calculation/differenzEinigungsgebuehr'
import calcGegnerTerminsGebuehrFaktor from '../../calculation/gegnerTerminsGebuehrFaktor'
import calcGerichtsverfahrenKV from '../../calculation/gerichtsverfahrenKV'

class Calculator extends React.Component {
    constructor(props) {
        super(props)

        this.calculationCallbacks = [
            {
                callback: calcRechtsGebuehr
            },
            {
                callback: calcVerfahrensGebuehrFaktor,
                callOnly: [
                    'anzahlMandanten', 
                    'anzahlGegner',
                    'anzahlGegnerRA', 
                    'gemAngelegenheit'
                ]
            },
            {
                callback: calcAuslagenGegner,
                callOnly: [
                    'anzahlGegner',
                    'anzahlGegnerRA', 
                    'geschaeftsGebuehrvv2300Value', 
                    'einigungsGebuehrAGValue', 
                ]
            },
            {
                callback: calcVerfahrensGebuehr
            },
            {
                callback: calcUbvVerfahrensGebuehr
            },
            {
                callback: calcAnrechenbarerTeil
            },            
            {
                callback: calcTerminsGebuehr
            },            
            {
                callback: calcUbvTerminsGebuehr
            },            
            {
                callback: calcZusatzGebuehr
            },            
            {
                callback: calcEinigungsGebuehr
            },            
            {
                callback: calcProfiGebuehr
            },            
            {
                callback: calcDifferenzVerfahrensgebuer
            },            
            {
                callback: calcDifferenzEinigungsgebuehr
            },            
            {
                callback: calcGegnerTerminsGebuehrFaktor,
                callOnly: [
                    'anzahlGegner',
                    'anzahlGegnerRA'
                ]
            },
            {
                callback: calcGerichtsverfahrenKV
            },
        ]

        this.calculationCallbacks.forEach((callback, id) => {
            const cbName = 'cb' + id
            callback.name = cbName
            this[cbName] = callback.callback.bind(this)
        })

        this.state = defaultState

        this.handleInput = this.handleInput.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSwitch = this.handleSwitch.bind(this)
        this.handleResize = this.handleResize.bind(this)

        this.handles = {
            handleInput: this.handleInput,
            handleFocus: this.handleFocus,
            handleBlur: this.handleBlur,
            handleChange: this.handleChange,
            handleSwitch: this.handleSwitch
        }

        this.referenceFields = {
            streitwert: [
                'streitwertI1',
                'streitwertI2',
                'streitwertI3'
            ]
        }

        if (! window.matchMedia('(min-width: 960px)').matches) {
            this.state.mobile = true;
        }

        this.calculate = this.calculate.bind(this)
    }

    componentDidMount() {
        this.calculated = 0

        window.addEventListener('resize', this.handleResize)
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
    }

    handleResize() {
        if (window.matchMedia('(min-width: 960px)').matches) {
            this.setState({
                mobile: false
            });
        }
        else {
            this.setState({
                mobile: true
            });            
        }
    }

    calculate(fieldName = null) {
        if (this.state.streitwert.calcValue <= 0) {
            return;
        }

        const instances = ['AG', 'I1', 'I2', 'I3']
        const instancesWithoutAg = ['I1', 'I2', 'I3']

        let newState = {}

        const einigungsGebuehrChecks = [
            {
                checkbox: 'einigungsGebuehrAG',
                instanz: 'AG'
            },
            {
                checkbox: 'einigungsGebuehrI1',
                instanz: 'I1'
            },
            {
                checkbox: 'einigungsGebuehrI2',
                instanz: 'I2'
            },
            {
                checkbox: 'einigungsGebuehrI3',
                instanz: 'I3'
            }
        ]

        let instanzBefore = false

        einigungsGebuehrChecks.forEach((einigungsGebuehrCheck) => {
            if (instanzBefore) {
                newState = {
                    ...newState,
                    ['stufe' + einigungsGebuehrCheck.instanz]: {
                        checked: false
                    }
                }
            }
            else {
                instanzBefore = this.state[einigungsGebuehrCheck.checkbox].checked ? einigungsGebuehrCheck.instanz : false
            }
        })

        if (this.state.honorar !== 'rvg') {
            newState = {
                ...newState,
                stufeAG: {
                    checked: false
                }
            }
        }
        
        let sum = {
            eigenerAnwalt: {
                AG: 0,
                I1: 0,
                I2: 0,
                I3: 0,
                ustAG: 0,
                ustI1: 0,
                ustI2: 0,
                ustI3: 0,
                gesamt: 0
            },
            gegnerAnwalt: {
                I1: 0,
                I2: 0,
                I3: 0,
                ustI1: 0,
                ustI2: 0,
                ustI3: 0,
                gesamt: 0
            },
            gericht: {
                I1: 0,
                I2: 0,
                I3: 0,
                ustI1: 0,
                ustI2: 0,
                ustI3: 0,
                gesamt: 0
            },
            gesamtAg: 0,
            gesamtI1: 0,
            gesamtI2: 0,
            gesamtI3: 0,
            gesamtProzessKosten: 0
        }

        this.calculationCallbacks.forEach((callback) => {
            if (callback.callOnly && ! callback.callOnly.includes(fieldName) ) {
                return;
            }

            const result = this[callback.name](callback.parameter)

            instances.forEach((instance) => {
                sum.eigenerAnwalt[instance] += result.value.eigenerAnwalt[instance] ? result.value.eigenerAnwalt[instance] : 0
            })

            instancesWithoutAg.forEach((instance) => {
                sum.gegnerAnwalt[instance] += result.value.gegnerAnwalt[instance] ? result.value.gegnerAnwalt[instance] : 0
                sum.gericht[instance] += result.value.gericht[instance] ? result.value.gericht[instance] : 0
            })

            newState = {...newState, ...result.newState}
        })

        instancesWithoutAg.forEach((instance) => {
            const ubvAuslagen = this.state.ubv.checked ? this.state['ubvAuslagen' + instance].calcValue : 0
            sum.eigenerAnwalt[instance] += this.state['auslagen' + instance].calcValue + this.state['sonstKosten' + instance].calcValue + ubvAuslagen + this.state['privGutachten' + instance].calcValue
            sum.gegnerAnwalt[instance] += this.state['gaAuslagen' + instance].calcValue + this.state['gaSonstKosten' + instance].calcValue
            sum.gericht[instance] += this.state['sachverstaendiger' + instance].calcValue
        })

        sum.eigenerAnwalt.AG += this.state.auslagenAG.calcValue + this.state.privGutachtenAG.calcValue

        if (this.state.mandantenUst.checked) {
            instances.forEach((instance) => {
                sum.eigenerAnwalt['ust' + instance] = sum.eigenerAnwalt[instance] * .19
                sum.eigenerAnwalt[instance] += sum.eigenerAnwalt['ust' + instance]
            })
        }

        if (this.state.gegnerUst.checked) {
            instances.forEach((instance) => {
                if (instance === 'AG') {
                    return
                }

                sum.gegnerAnwalt['ust' + instance] = sum.gegnerAnwalt[instance] * .19
                sum.gegnerAnwalt[instance] += sum.gegnerAnwalt['ust' + instance]
            })
        }

        sum.eigenerAnwalt.gesamt = 
            (this.state.stufeAG.checked ? sum.eigenerAnwalt.AG : 0) + 
            (this.state.stufeI1.checked ? sum.eigenerAnwalt.I1 : 0) +
            (this.state.stufeI2.checked ? sum.eigenerAnwalt.I2 : 0) +
            (this.state.stufeI3.checked ? sum.eigenerAnwalt.I3 : 0)

        sum.gegnerAnwalt.gesamt = 
            (this.state.stufeI1.checked ? sum.gegnerAnwalt.I1 : 0) +
            (this.state.stufeI2.checked ? sum.gegnerAnwalt.I2 : 0) +
            (this.state.stufeI3.checked ? sum.gegnerAnwalt.I3 : 0)

        sum.gericht.gesamt = 
            (this.state.stufeI1.checked ? sum.gericht.I1 : 0) +
            (this.state.stufeI2.checked ? sum.gericht.I2 : 0) +
            (this.state.stufeI3.checked ? sum.gericht.I3 : 0)

        if (this.state.honorar !== 'rvg') {
            sum.eigenerAnwalt.I1 = this.state.hourlyHours.calcValue * this.state.hourlyRate.calcValue + this.state.auslagenI1.calcValue + this.state.sonstKostenI1.calcValue

            if (this.state.mandantenUst.checked) {
                sum.eigenerAnwalt.ustI1 = sum.eigenerAnwalt.I1 * .19
                sum.eigenerAnwalt.I1 += sum.eigenerAnwalt.ustI1
            }

            sum.eigenerAnwalt.gesamt =  sum.eigenerAnwalt.I1
        }

        let erfolgsVereinbarung = {}
        let newStateErfolgsVereinbarung = {}

        instances.forEach((instance) => {
            erfolgsVereinbarung['erfolgsHonorar' + instance] = this.state['erfolgsHonorar' + instance].calcValue / 100 * sum.eigenerAnwalt[instance]
            erfolgsVereinbarung['nichtErfolgsHonorar' + instance] = this.state['nichtErfolgsHonorar' + instance].calcValue / 100 * sum.eigenerAnwalt[instance]
            erfolgsVereinbarung['gesamtBeiErfolg' + instance] = sum.eigenerAnwalt[instance] + erfolgsVereinbarung['erfolgsHonorar' + instance]
            erfolgsVereinbarung['gesamtBeiNichtErfolg' + instance] = sum.eigenerAnwalt[instance] - erfolgsVereinbarung['nichtErfolgsHonorar' + instance]

            if (this.state.mandantenUst.checked) {
                erfolgsVereinbarung['gesamtBeiErfolg' + instance] += erfolgsVereinbarung['gesamtBeiErfolg' + instance] * .19
                erfolgsVereinbarung['gesamtBeiNichtErfolg' + instance] += erfolgsVereinbarung['gesamtBeiNichtErfolg' + instance] * .19
            }

            newStateErfolgsVereinbarung = {
                ...newStateErfolgsVereinbarung,
                ['erfolgsHonorar' + instance + 'Value']: {
                    calcValue: erfolgsVereinbarung['erfolgsHonorar' + instance],
                    styledValue: getStyledValue(erfolgsVereinbarung['erfolgsHonorar' + instance], 'euro', 2),    
                },
                ['nichtErfolgsHonorar' + instance + 'Value']: {
                    calcValue: erfolgsVereinbarung['nichtErfolgsHonorar' + instance],
                    styledValue: getStyledValue(erfolgsVereinbarung['nichtErfolgsHonorar' + instance], 'euro', 2),    
                },
                ['gesamtEigenerErfolg' + instance]: {
                    calcValue: erfolgsVereinbarung['gesamtBeiErfolg' + instance],
                    styledValue: getStyledValue(erfolgsVereinbarung['gesamtBeiErfolg' + instance], 'euro', 2),    
                },
                ['gesamtEigenerNichtErfolg' + instance]: {
                    calcValue: erfolgsVereinbarung['gesamtBeiNichtErfolg' + instance],
                    styledValue: getStyledValue(erfolgsVereinbarung['gesamtBeiNichtErfolg' + instance], 'euro', 2),    
                }
            }
        })


        sum.gesamtAG = this.state.stufeAG.checked ? sum.eigenerAnwalt.AG : 0
        sum.gesamtI1 = this.state.stufeI1.checked ? sum.eigenerAnwalt.I1 + sum.gegnerAnwalt.I1 + sum.gericht.I1 : 0
        sum.gesamtI2 = this.state.stufeI2.checked ? sum.eigenerAnwalt.I2 + sum.gegnerAnwalt.I2 + sum.gericht.I2 : 0    
        sum.gesamtI3 = this.state.stufeI3.checked ? sum.eigenerAnwalt.I3 + sum.gegnerAnwalt.I3 + sum.gericht.I3 : 0    

        if (this.state.gesamtkostenEigenerAnwalt.checked) {
            sum.gesamtProzessKosten += sum.eigenerAnwalt.gesamt
        }

        if (this.state.gesamtkostenFremdAnwalt.checked) {
            sum.gesamtProzessKosten += sum.gegnerAnwalt.gesamt
        }

        if (this.state.gesamtkostenGericht.checked) {
            sum.gesamtProzessKosten += sum.gericht.gesamt
        }

        newState = {
            ...newState,
            gesamtEigenerAAG: {
                calcValue: sum.eigenerAnwalt.AG,
                styledValue: getStyledValue(sum.eigenerAnwalt.AG, 'euro', 2)
            },
            gesamtEigenerAI1: {
                calcValue: sum.eigenerAnwalt.I1,
                styledValue: getStyledValue(sum.eigenerAnwalt.I1, 'euro', 2)
            },
            gesamtEigenerAI2: {
                calcValue: sum.eigenerAnwalt.I2,
                styledValue: getStyledValue(sum.eigenerAnwalt.I2, 'euro', 2)
            },
            gesamtEigenerAI3: {
                calcValue: sum.eigenerAnwalt.I3,
                styledValue: getStyledValue(sum.eigenerAnwalt.I3, 'euro', 2)
            },
            gesamtFremdAI1: {
                calcValue: sum.gegnerAnwalt.I1,
                styledValue: getStyledValue(sum.gegnerAnwalt.I1, 'euro', 2)
            },
            gesamtFremdAI2: {
                calcValue: sum.gegnerAnwalt.I2,
                styledValue: getStyledValue(sum.gegnerAnwalt.I2, 'euro', 2)
            },
            gesamtFremdAI3: {
                calcValue: sum.gegnerAnwalt.I3,
                styledValue: getStyledValue(sum.gegnerAnwalt.I3, 'euro', 2)
            },
            gerichtskostenGesamtI1: {
                calcValue: sum.gericht.I1,
                styledValue: getStyledValue(sum.gericht.I1, 'euro', 2)
            },
            gerichtskostenGesamtI2: {
                calcValue: sum.gericht.I2,
                styledValue: getStyledValue(sum.gericht.I2, 'euro', 2)
            },
            gerichtskostenGesamtI3: {
                calcValue: sum.gericht.I3,
                styledValue: getStyledValue(sum.gericht.I3, 'euro', 2)
            },
            Ust19AG: {
                calcValue: sum.eigenerAnwalt.ustAG,
                styledValue: getStyledValue(sum.eigenerAnwalt.ustAG, 'euro', 2)
            },
            Ust19I1: {
                calcValue: sum.eigenerAnwalt.ustI1,
                styledValue: getStyledValue(sum.eigenerAnwalt.ustI1, 'euro', 2)
            },
            Ust19I2: {
                calcValue: sum.eigenerAnwalt.ustI2,
                styledValue: getStyledValue(sum.eigenerAnwalt.ustI2, 'euro', 2)
            },
            Ust19I3: {
                calcValue: sum.eigenerAnwalt.ustI3,
                styledValue: getStyledValue(sum.eigenerAnwalt.ustI3, 'euro', 2)
            },
            gaUst19I1: {
                calcValue: sum.gegnerAnwalt.ustI1,
                styledValue: getStyledValue(sum.gegnerAnwalt.ustI1, 'euro', 2)
            },
            gaUst19I2: {
                calcValue: sum.gegnerAnwalt.ustI2,
                styledValue: getStyledValue(sum.gegnerAnwalt.ustI2, 'euro', 2)
            },
            gaUst19I3: {
                calcValue: sum.gegnerAnwalt.ustI3,
                styledValue: getStyledValue(sum.gegnerAnwalt.ustI3, 'euro', 2)
            },
            gesamtKostenEigenerAnwaltValue: {
                calcValue: sum.eigenerAnwalt.gesamt,
                styledValue: getStyledValue(sum.eigenerAnwalt.gesamt, 'euro', 2)
            },
            gesamtkostenFremdAnwaltValue: {
                calcValue: sum.gegnerAnwalt.gesamt,
                styledValue: getStyledValue(sum.gegnerAnwalt.gesamt, 'euro', 2)
            },
            gesamtkostenGerichtValue: {
                calcValue: sum.gericht.gesamt,
                styledValue: getStyledValue(sum.gericht.gesamt, 'euro', 2)
            },
            summeAussergerichtlich: {
                calcValue: sum.gesamtAG,
                styledValue: getStyledValue(sum.gesamtAG, 'euro', 2)
            },
            summeI1: {
                calcValue: sum.gesamtI1,
                styledValue: getStyledValue(sum.gesamtI1, 'euro', 2)
            },
            summeI2: {
                calcValue: sum.gesamtI2,
                styledValue: getStyledValue(sum.gesamtI2, 'euro', 2)
            },
            summeI3: {
                calcValue: sum.gesamtI3,
                styledValue: getStyledValue(sum.gesamtI3, 'euro', 2)
            },
            gesamtprozesskosten: {
                calcValue: sum.gesamtProzessKosten,
                styledValue: getStyledValue(sum.gesamtProzessKosten, 'euro', 2)
            },
            ...newStateErfolgsVereinbarung
        }

        this.setState(newState, () => {
            if (this.calculated === 0) {
                this.calculate()
                this.calculated = 1
            }
            else {
                this.calculated = 0
            }
        })

    }

    handleInput(event, type, decimals) {
        const {name, dataset} = event.target
        let {value} = event.target
        let nullValue = dataset.min || 0

        value = getEditValue(value)

        if (dataset.min && parseFloat(value) < parseInt(dataset.min)) {
            value = dataset.min
        }

        if (['streitwert', 'streitwertI1', 'streitwertI2', 'streitwertI3'].includes(name)) {
            const streitwertHoehe = getCalcValueFromInput(value)
            const maxStreitwert = this.state.anzahlMandanten.calcValue >= 2 && this.state.gemAngelegenheit.checked ? Math.min(100000000, this.state.anzahlMandanten.calcValue * 30000000) : 30000000

            if (streitwertHoehe > maxStreitwert) {
                value = maxStreitwert.toString()
            }
        }

        const editValue = getEditValue(value, type) || nullValue.toString()
        const calcValue = getCalcValue(editValue)
        const styledValue = getStyledValue(calcValue, type, decimals)
        const inputValue = getInputValue(value, type)

        let newState = {}

        newState[name] = {
                inputValue,
                calcValue,
                editValue,
                styledValue
        }

        if (this.referenceFields[name]) {
            this.referenceFields[name].forEach(field => {
                newState[field] = {
                    inputValue: styledValue,
                    calcValue,
                    editValue,
                    styledValue
                }
            })
        }

        this.setState(newState, () => {
            this.calculate(name)
        })

        switch (name) {
            case 'anzahlMandanten':
                let newMandantState = {
                    showGemAngelegenheit: value >= 2
                }

                let recalc = false;

                if (value < 2) {
                    recalc = true

                    newMandantState.gemAngelegenheit = {
                        checked: false
                    }
                }

                this.setState(newMandantState, () => {
                    if (recalc) {
                        this.calculate('gemAngelegenheit')
                    }
                })
                break
            default:
                break
        }
    }

    handleFocus(event) {
        const {name} = event.target

        this.setState(prevState => {
            const {calcValue, editValue, styledValue} = prevState[name]
            const inputValue = editValue

            return {
                [name]: {
                    inputValue,
                    calcValue,
                    editValue,
                    styledValue    
                }
            }
        })
    }

    handleBlur(event) {
        const {name} = event.target

        this.setState(prevState => {
            const {calcValue, editValue, styledValue} = prevState[name]
            const inputValue = styledValue

            return {
                [name]: {
                    inputValue,
                    calcValue,
                    editValue,
                    styledValue    
                }
            }
        })
    }

    handleChange(event) {
        const {name, type, value} = event.target

        switch (type) {
            case 'radio':
                this.setState({
                    [name]: value
                }, () => {
                    this.calculate(name)
                })
                break

            default:
                this.setState(prevState => {
                    const instances = ['I3', 'I2', 'I1', 'AG']

                    let newState = {
                        [name]: prevState[name]
                    }
                    
                    if (name === 'stufeVergleich') {
                        for (let i = 0; i < instances.length; i++) {
                            if (prevState['stufe' + instances[i]].checked && ! prevState[name].checked) {
                                newState['einigungsGebuehr' + instances[i]] = {
                                    checked: true
                                }

                                break
                            }
                            else if (prevState[name].checked) {
                                newState['einigungsGebuehr' + instances[i]] = {
                                    checked: false
                                }
                            }
                        }
                    }

                    instances.forEach((instance) => {
                        if (name === 'einigungsGebuehr' + instance && ! prevState['einigungsGebuehr' + instance].checked) {
                            newState.stufeVergleich = {
                                checked: true
                            }
                        }
                    })

                    if (value === '1') {
                        newState[name].checked = ! prevState[name].checked
                    }
                    else if (value === prevState[name].checked) {
                        newState[name].checked = false
                    }
                    else {
                        newState[name].checked = value
                    }

                    if (name === 'gemAngelegenheit') {
                        const streitwertHoehe = this.state.streitwert.calcValue
                        const maxStreitwert = this.state.anzahlMandanten.calcValue >= 2 && newState[name].checked ? Math.min(100000000, this.state.anzahlMandanten.calcValue * 30000000) : 30000000            

                        if (streitwertHoehe > maxStreitwert) {
                            newState.streitwert = {
                                calcValue: maxStreitwert,
                                editValue: getEditValueFromCalcValue(maxStreitwert),
                                styledValue: getStyledValue(maxStreitwert, 'euro', 2),
                                inputValue: getInputValue(getEditValueFromCalcValue(maxStreitwert), 'euro'),
                            }
                        }

                        instances.forEach((instance) => {
                            if (instance === 'AG') {
                                return;
                            }

                            const swName = 'streitwert' + instance
                            const hoehe = this.state[swName].calcValue

                            if (hoehe > maxStreitwert) {
                                newState[swName] = {
                                    calcValue: maxStreitwert,
                                    editValue: getEditValueFromCalcValue(maxStreitwert),
                                    styledValue: getStyledValue(maxStreitwert, 'euro', 2),
                                    inputValue: getInputValue(getEditValueFromCalcValue(maxStreitwert), 'euro'),
                                }    
                            }
                        })
    
                    }
                
                    return newState

                }, () => {
                    this.calculate(name)
                })
                break
        }

    }

    handleSwitch(event) {
        event.preventDefault()
        event.stopPropagation()

        this.setState(prevState => {
            let newState = prevState

            newState.gebuehrenOrdnung = ! newState.gebuehrenOrdnung

            if (! newState.gebuehrenOrdnung) {
                newState.zusatzGebuehrVV1010I1.checked = false
                newState.zusatzGebuehrVV1010I2.checked = false
            }

            return {
                gebuehrenOrdnung: newState.gebuehrenOrdnung
            }
        }, () => {
            this.calculate('gebuehrenOrdnung')
        })
    }

    render() {
        return (
            <CalculatorForm 
                data={this.state}
                handles={this.handles}
            />
        )
    }
}

export default Calculator