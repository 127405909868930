export function getEditValue(value) {
    return value.replace(/[^\d^,]/, '')
}

export function getEditValueFromCalcValue(value) {
    value = value.toString()
    return value.replace('.', ',')
}

export function getCalcValue(editValue) {
    return parseFloat(editValue.replace(',', '.'))
}

export function getStyledValue(calcValue, type, decimals) {
    let styledValue = new Intl.NumberFormat('de-DE', {
        style: 'decimal', 
        maximumFractionDigits: decimals, 
        minimumFractionDigits: decimals
    }).format(calcValue)

    if (type === 'euro') {
        styledValue += ' €'
    }
    else if(type === 'percent') {
        styledValue += ' %'
    }

    return styledValue
}

export function getCalcValueFromInput(value) {
    return getCalcValue(getEditValue(value))
}

export function getInputValue(value, type = null) {
    if (type !== 'euro') {
        return value
    }    

    value = getEditValue(value).replace(/,/g, '.')

    const numberOfFullstops = value.split('.').length - 1

    if (numberOfFullstops === 2) {
        value = value.substring(0, value.length - 1)
    }
    
    let decimals = 0

    let inputIncludesStop = false
    if (value.slice(-1) === '.') {
        inputIncludesStop = true
    }

    if (value.includes('.')) {
        const valTest = value.split('.')
        decimals = valTest[1].length <= 2 ? valTest[1].length : 2
    }
    
    value = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals
    }).format(value)

    if (inputIncludesStop) {
        value = value + ','
    }

    return value
}