import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcDifferenzEinigungsgebuehr() {
    if (! this.state.mehrvergleich.checked) {
        return {
            value: {
                eigenerAnwalt: {},
                gegnerAnwalt: {},
                gericht: {}
            },
            newState: {}
        }
    }

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }
    let newState = {}

    const instances = ['I1', 'I2', 'I3']

    instances.forEach((instance) => {
        let diffEinigungsgebuehr = 0

        if (this.state['einigungsGebuehr' + instance].checked) {
            const rvGvalue = calcGebuehr(this.state['mehrvergleich' + instance].calcValue, this.state.gebuehrenOrdnung)
            const zusatzGebuehr = 1.5 * rvGvalue.rvg
            const maxFaktor = 1.5
            const sumMehrwertbetragStreitwert = this.state['mehrvergleich' + instance].calcValue + this.state['streitwert' + instance].calcValue
            const rvGvalue2 = calcGebuehr(sumMehrwertbetragStreitwert, this.state.gebuehrenOrdnung)
            const gesamtGebuehr = maxFaktor * rvGvalue2.rvg
            const einigungsGebuehr = this.state['einigungsGebuehr' + instance + 'Value'].calcValue
            const minGebuehr = Math.min(gesamtGebuehr, einigungsGebuehr + zusatzGebuehr)
            
            diffEinigungsgebuehr = minGebuehr - einigungsGebuehr
        }

        value.eigenerAnwalt[instance] = diffEinigungsgebuehr
        value.gegnerAnwalt[instance] = diffEinigungsgebuehr

        newState = {
            ...newState,
            ['diffEinigungsGebuehr' + instance]: {
                calcValue: diffEinigungsgebuehr,
                styledValue: getStyledValue(diffEinigungsgebuehr, 'euro', 2),    
            },
            ['gaDiffEinigungsGebuehr' + instance]: {
                calcValue: diffEinigungsgebuehr,
                styledValue: getStyledValue(diffEinigungsgebuehr, 'euro', 2),    
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcDifferenzEinigungsgebuehr