import React from 'react'

import AccordionItem from './AccordionItem'

class Accordion extends React.Component {
    render() {
        const accordionElements = this.props.data.map((data, key) => (
            <div key={key} className="accordion">
                <AccordionItem key={key} openOnChange={this.props.openOnChange} indicator={data.indicator} {...data} />
            </div>
        ))

        return (
            <div className={`accordion-wrapper${this.props.noAccOnMobile ? ' accordion-wrapper--no-mobile' : ''}${this.props.options.withBorder ? ' accordion-wrapper--with-border' : ''}${this.props.options.noMargin ? ' accordion-wrapper--no-margin' : ''}${this.props.backgroundColor ? ' ' + this.props.backgroundColor : ''}`}>
                {accordionElements}
            </div>
        )
    }
}

export default Accordion