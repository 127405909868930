import {getStyledValue} from '../Helpers/valueHelpers'

/**
 * WERTBERECHNUNG:
 * 
 */

function calcZusatzGebuehr() {
    const instances = ['I1', 'I2']

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let zusatzGebuehr = 0

        if (this.state['zusatzGebuehrVV1010' + instance].checked) {
            zusatzGebuehr = .3 * this.state['geb13Rvg' + instance].calcValue
        }

        value.eigenerAnwalt[instance] = zusatzGebuehr
        value.gegnerAnwalt[instance] = zusatzGebuehr

        newState = {
            ...newState,
            ['zusatzGebuehrVV1010' + instance + 'Value']: {
                calcValue: zusatzGebuehr,
                styledValue: getStyledValue(zusatzGebuehr, 'euro', 2),
            },
            ['gaZusatzGebuehrVV1010' + instance + 'Value']: {
                calcValue: zusatzGebuehr,
                styledValue: getStyledValue(zusatzGebuehr, 'euro', 2),
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcZusatzGebuehr