import {getStyledValue} from '../Helpers/valueHelpers'
import calcGebuehr from '../Helpers/calcgebuehrenOrdnung'

function calcDifferenzVerfahrensgebuer() {
    if (! this.state.mehrvergleich.checked) {
        return {
            value: {
                eigenerAnwalt: {},
                gegnerAnwalt: {},
                gericht: {}
            },
            newState: {}
        }
    }

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    const instances = ['I1', 'I2', 'I3']

    const faktoren = {
        I1: .8,
        I2: 1.1,
        I3: 1.8
    }

    instances.forEach((instance) => {
        const rvGvalue = calcGebuehr(this.state['mehrvergleich' + instance].calcValue, this.state.gebuehrenOrdnung)
        const zusatzGebuehr = faktoren[instance] * rvGvalue.rvg
        const maxFaktor = Math.max(faktoren[instance], this.state['verfahrensGebuehrvv3100' + instance].calcValue)
        const sumMehrwertbetragStreitwert = this.state['mehrvergleich' + instance].calcValue + this.state['streitwert' + instance].calcValue
        const rvGvalue2 = calcGebuehr(sumMehrwertbetragStreitwert, this.state.gebuehrenOrdnung)
        const gesamtGebuehr = maxFaktor * rvGvalue2.rvg
        const verfahrensGebuehrvv3100 = this.state['verfahrensGebuehrvv3100' + instance + 'Value'].calcValue
        const minGebuehr = Math.min(gesamtGebuehr, verfahrensGebuehrvv3100 + zusatzGebuehr)
        const mehrvergleichValue = minGebuehr - verfahrensGebuehrvv3100

        value.eigenerAnwalt[instance] = mehrvergleichValue
        value.gegnerAnwalt[instance] = mehrvergleichValue

        newState = {
            ...newState,
            ['diffVFGebuehr' + instance]: {
                calcValue: mehrvergleichValue,
                styledValue: getStyledValue(mehrvergleichValue, 'euro', 2),    
            },
            ['gaDiffVFGebuehr' + instance]: {
                calcValue: mehrvergleichValue,
                styledValue: getStyledValue(mehrvergleichValue, 'euro', 2),    
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcDifferenzVerfahrensgebuer