const defaultState = {

    /***************************************************************
     * INPUTFELDER
     */

    streitwert: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: 'D7'
    },
    streitwertI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: 'D7'
    },
    streitwertI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: 'D7'
    },
    streitwertI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: 'D7'
    },
    mehrvergleichI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    mehrvergleichI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    mehrvergleichI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    anzahlMandanten: {
        inputValue: '1',
        calcValue: 1,
        editValue: 1,
        styledValue: '1',
        excel: 'D12'
    },
    anzahlGegner: {
        inputValue: '1',
        calcValue: 1,
        editValue: 1,
        styledValue: '1',
        excel: 'D13'
    },
    anzahlGegnerRA: {
        inputValue: '1',
        calcValue: 1,
        editValue: 1,
        styledValue: '1',
        excel: 'D14'
    },
    geschaeftsGebuehrvv2300: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
        excel: 'F21'
    },
    verfahrensGebuehrvv3100I1: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
        excel: 'F7'
    },
    verfahrensGebuehrvv3100I2: {
        inputValue: '1,6',
        calcValue: 1.6,
        editValue: '1,6',
        styledValue: '1,6',
        excel: 'F10'
    },
    verfahrensGebuehrvv3100I3: {
        inputValue: '2,3',
        calcValue: 2.3,
        editValue: '2,3',
        styledValue: '2,3',
        excel: 'F11'
    },
    ubvVerfahrensGebuehrI1: {
        inputValue: '0,65',
        calcValue: 0.65,
        editValue: '0,65',
        styledValue: '0,65',
    },
    ubvVerfahrensGebuehrI2: {
        inputValue: '0,8',
        calcValue: 0.8,
        editValue: '0,8',
        styledValue: '0,8',
    },
    ubvVerfahrensGebuehrI3: {
        inputValue: '0,8',
        calcValue: 0.8,
        editValue: '0,8',
        styledValue: '0,8',
    },
    gaGeschaeftsGebuehrvv2300: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
        excel: 'F21'
    },
    gaVerfahrensGebuehrvv3100I1: {
        inputValue: '1,3',
        calcValue: 1.3,
        editValue: '1,3',
        styledValue: '1,3',
        excel: 'F7'
    },
    gaVerfahrensGebuehrvv3100I2: {
        inputValue: '1,6',
        calcValue: 1.6,
        editValue: '1,6',
        styledValue: '1,6',
        excel: 'F10'
    },
    gaVerfahrensGebuehrvv3100I3: {
        inputValue: '2,3',
        calcValue: 2.3,
        editValue: '2,3',
        styledValue: '2,3',
        excel: 'F11'
    },
    terminsGebuehrI1: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
        excel: 'F8'
    },
    terminsGebuehrI2: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
        excel: 'F10'
    },
    terminsGebuehrI3: {
        inputValue: '1,5',
        calcValue: 1.5,
        editValue: '1,5',
        styledValue: '1,5',
        excel: 'F12'
    },
    ubvTerminsGebuehrI1: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
    },
    ubvTerminsGebuehrI2: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
    },
    ubvTerminsGebuehrI3: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
    },
    gaTerminsGebuehrI1: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
        excel: 'F8'
    },
    gaTerminsGebuehrI2: {
        inputValue: '1,2',
        calcValue: 1.2,
        editValue: '1,2',
        styledValue: '1,2',
        excel: 'F10'
    },
    gaTerminsGebuehrI3: {
        inputValue: '1,5',
        calcValue: 1.5,
        editValue: '1,5',
        styledValue: '1,5',
        excel: 'F12'
    },
    auslagenAG: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    auslagenI1: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    auslagenI2: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    auslagenI3: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    gaAuslagenAG: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    gaAuslagenI1: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    gaAuslagenI2: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    gaAuslagenI3: {
        inputValue: '20,00 €',
        calcValue: 20,
        editValue: 20,
        styledValue: '20,00 €',
        excel: 'D24'
    },
    sonstKostenI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    sonstKostenI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    sonstKostenI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaSonstKostenI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaSonstKostenI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaSonstKostenI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    privGutachtenAG: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    privGutachtenI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    privGutachtenI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    privGutachtenI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    erfolgsHonorarAG: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10',
        excel: ''
    },
    erfolgsHonorarI1: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    erfolgsHonorarI2: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    erfolgsHonorarI3: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    nichtErfolgsHonorarAG: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    nichtErfolgsHonorarI1: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    nichtErfolgsHonorarI2: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    nichtErfolgsHonorarI3: {
        inputValue: '10 %',
        calcValue: 10,
        editValue: '10',
        styledValue: '10 %',
        excel: ''
    },
    gerichtsverfahrenKVI1: {
        inputValue: '3,0',
        calcValue: 3,
        editValue: '3,0',
        styledValue: '3,0',
        excel: ''
    },
    gerichtsverfahrenKVI2: {
        inputValue: '4,0',
        calcValue: 4,
        editValue: '4,0',
        styledValue: '4,0',
        excel: ''
    },
    gerichtsverfahrenKVI3: {
        inputValue: '5,0',
        calcValue: 5,
        editValue: '5,0',
        styledValue: '5,0',
        excel: ''
    },
    sachverstaendigerI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    sachverstaendigerI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    sachverstaendigerI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    hourlyHours: {
        inputValue: '0,0',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,0',
        excel: ''
    },
    hourlyRate: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    ubvAuslagenI1: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
    },
    ubvAuslagenI2: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
    },
    ubvAuslagenI3: {
        inputValue: '0,00 €',
        calcValue: 0,
        editValue: 0,
        styledValue: '0,00 €',
    },


    /***************************************************************
     * BOOLEANS
     */

    mandantenUst: {
        checked: true,
        excel: 'L10'
    },
    gegnerUst: {
        checked: true,
        excel: 'L11'
    },
    gemAngelegenheit: {
        checked: false,
        excel: 'L17'
    },
    stufeAG: {
        checked: false,
        excel: ''
    },
    stufeI1: {
        checked: true,
        excel: 'L4'
    },
    stufeI2: {
        checked: false,
        excel: 'L5'
    },
    stufeI3: {
        checked: false,
        excel: 'L6'
    },
    stufeVergleich: {
        checked: false,
        excel: 'L14-16'
    },
    gesamtkostenEigenerAnwalt: {
        checked: true
    },
    gesamtkostenFremdAnwalt: {
        checked: true
    },
    gesamtkostenGericht: {
        checked: true
    },  
    mehrvergleich: {
        checked: false
    },
    erfolgshonorar: {
        checked: false
    },
    ubv: {
        checked: false
    },
    zusatzGebuehrVV1010I1: {
        checked: false
    },
    zusatzGebuehrVV1010I2: {
        checked: false
    },
    einigungsGebuehrAG: {
        checked: false
    },
    einigungsGebuehrI1: {
        checked: false
    },
    einigungsGebuehrI2: {
        checked: false
    },
    einigungsGebuehrI3: {
        checked: false
    },
    profiGebuehr: {
        checked: false
    },


    /***************************************************************
     * OUTPUTFELDER
     */

    gesamtprozesskosten: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D10'
    },
    gesamtKostenEigenerAnwaltValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtkostenFremdAnwaltValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtkostenGerichtValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    summeAussergerichtlich: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    summeI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D62'
    },
    summeI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F62'
    },
    summeI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H62'
    },
    geschaeftsGebuehrvv2300Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    verfahrensGebuehrvv3100I1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    verfahrensGebuehrvv3100I2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    verfahrensGebuehrvv3100I3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    ubvVerfahrensGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    ubvVerfahrensGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    ubvVerfahrensGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
    },
    gaGeschaeftsGebuehrvv2300Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    gaVerfahrensGebuehrvv3100I1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    gaVerfahrensGebuehrvv3100I2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    gaVerfahrensGebuehrvv3100I3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G21'
    },
    diffVFGebuehrI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    diffVFGebuehrI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    diffVFGebuehrI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffVFGebuehrI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffVFGebuehrI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffVFGebuehrI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    abzugAnrechTeil: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D39'
    },
    terminsGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D40'
    },
    terminsGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F40'
    },
    terminsGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H38'
    },
    ubvTerminsGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D40'
    },
    ubvTerminsGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F40'
    },
    ubvTerminsGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H38'
    },
    gaTerminsGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D40'
    },
    gaTerminsGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F40'
    },
    gaTerminsGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H38'
    },
    zusatzGebuehrVV1010I1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D33'
    },
    zusatzGebuehrVV1010I2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D34'
    },
    gaZusatzGebuehrVV1010I1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D33'
    },
    gaZusatzGebuehrVV1010I2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D34'
    },
    einigungsGebuehrAGValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'G22'
    },
    einigungsGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D41'
    },
    einigungsGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F41'
    },
    einigungsGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H41'
    },
    gaEinigungsGebuehrI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D41'
    },
    gaEinigungsGebuehrI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'F41'
    },
    gaEinigungsGebuehrI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'H41'
    },
    diffEinigungsGebuehrI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    diffEinigungsGebuehrI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    diffEinigungsGebuehrI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffEinigungsGebuehrI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffEinigungsGebuehrI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaDiffEinigungsGebuehrI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    profiGebuehrAG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    profiGebuehrI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    profiGebuehrI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    profiGebuehrI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    Ust19AG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    Ust19I1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    Ust19I2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    Ust19I3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaUst19AG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaUst19I1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaUst19I2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gaUst19I3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    erfolgsHonorarAGValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    erfolgsHonorarI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    erfolgsHonorarI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    erfolgsHonorarI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    nichtErfolgsHonorarAGValue: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    nichtErfolgsHonorarI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    nichtErfolgsHonorarI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    nichtErfolgsHonorarI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerAAG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerAI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerAI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerAI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerErfolgAG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerErfolgI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerErfolgI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerErfolgI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerNichtErfolgAG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerNichtErfolgI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerNichtErfolgI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtEigenerNichtErfolgI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtFremdAAG: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtFremdAI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtFremdAI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gesamtFremdAI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gerichtsverfahrenKVI1Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D56'
    },
    gerichtsverfahrenKVI2Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D56'
    },
    gerichtsverfahrenKVI3Value: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: 'D56'
    },
    gerichtskostenGesamtI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gerichtskostenGesamtI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    gerichtskostenGesamtI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13Rvg: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgGedeckelt: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgGedeckeltI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgGedeckeltI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb13RvgGedeckeltI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34Gkg: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgMehrwertI1: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgMehrwertI2: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },
    geb34GkgMehrwertI3: {
        calcValue: 0,
        styledValue: '0,00 €',
        excel: ''
    },

    /***************************************************************
     * APPSTATE
     */

    showGemAngelegenheit: false,
    gebuehrenOrdnung: '2021',
    honorar: 'rvg',
    mobile: false
}

export default defaultState