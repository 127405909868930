import faktorenRvg from '../data/faktorenRvg'

import {getEditValueFromCalcValue, getStyledValue} from '../Helpers/valueHelpers'

function calcVerfahrensGebuehrFaktor() {
    const instances = ['AG', 'I1', 'I2', 'I3']
    const instancesSummen = {
        AG: 0,
        I1: 10,
        I2: 13,
        I3: 20,
    }

    let anzahlMandanten = this.state.anzahlMandanten.calcValue > 20 ? 20 : this.state.anzahlMandanten.calcValue
    const anzahlGegner = this.state.anzahlGegner.calcValue
    const anzahlGegnerRA = this.state.anzahlGegnerRA.calcValue
    
    let anzahlGesamt = 0
    
    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }
    
    let newState = {}
    
    instances.forEach((instance) => {
        if (instance === 'AG') {
            let faktorGeschaeftsgebuehr = 1.3
        
            for (let anzahlMandanten = 2; anzahlMandanten <= this.state.anzahlMandanten.calcValue; anzahlMandanten++) {
                faktorGeschaeftsgebuehr += .3
            }
        
            faktorGeschaeftsgebuehr = faktorGeschaeftsgebuehr > 2.5 ? 2.5 : faktorGeschaeftsgebuehr

            newState = {
                ...newState,
                geschaeftsGebuehrvv2300: {
                    inputValue: getStyledValue(faktorGeschaeftsgebuehr, 'number', 1),
                    calcValue: faktorGeschaeftsgebuehr,
                    editValue: getEditValueFromCalcValue(faktorGeschaeftsgebuehr),
                    styledValue: getStyledValue(faktorGeschaeftsgebuehr, 'number', 1),
                }    
            }

            return
        }
        
        let faktorVerfahrensGebuehr = 0
        let faktorVerfahrensGebuehrGegner = 0

        let fieldName = 'verfahrensGebuehrvv3100' + instance
        let fieldNameGegner = 'gaVerfahrensGebuehrvv3100' + instance
        
        if (anzahlGegner === 1 && anzahlGegnerRA === 1) {
            anzahlGesamt = 1
            faktorVerfahrensGebuehrGegner = faktorenRvg.gegnerAg[instance.toLowerCase()][anzahlGesamt]
        }
        else if (anzahlGegner === 1 && anzahlGegnerRA > 1) {
            anzahlGesamt = 1
            faktorVerfahrensGebuehrGegner = faktorenRvg.gegnerAg[instance.toLowerCase()][anzahlGesamt]
        }
        else if (anzahlGegner > 1 && anzahlGegnerRA === 1) {
            anzahlGesamt = anzahlGegner > 20 ? 20 : anzahlGegner
            faktorVerfahrensGebuehrGegner = faktorenRvg.gegnerAg[instance.toLowerCase()][anzahlGesamt]
        }
        else if (anzahlGegner > 1 && anzahlGegnerRA > 1 && anzahlGegner <= anzahlGegnerRA) {
            anzahlGesamt = anzahlGegner > 20 ? 20 : anzahlGegner
            faktorVerfahrensGebuehrGegner = faktorenRvg.gegnerRaVg[instance.toLowerCase()][anzahlGesamt]
        }
        else if (anzahlGegner > 1 && anzahlGegnerRA > 1 && anzahlGegner > anzahlGegnerRA) {
            anzahlGesamt = anzahlGegnerRA > 20 ? 20 : anzahlGegnerRA
            faktorVerfahrensGebuehrGegner = faktorenRvg.gegnerRaVg[instance.toLowerCase()][anzahlGesamt] + (anzahlGegner - anzahlGegnerRA) * .3
        }

        if (this.state.gemAngelegenheit.checked) {
            anzahlMandanten = 1;
        }

        const satzMandanten = Math.min(anzahlMandanten * 3, 23);
        faktorVerfahrensGebuehr = (satzMandanten + instancesSummen[instance]) / 10;

        newState = {
            ...newState,
            [fieldName]: {
                inputValue: getStyledValue(faktorVerfahrensGebuehr, 'number', 1),
                calcValue: faktorVerfahrensGebuehr,
                editValue: getEditValueFromCalcValue(faktorVerfahrensGebuehr),
                styledValue: getStyledValue(faktorVerfahrensGebuehr, 'number', 1),
            },
            [fieldNameGegner]: {
                inputValue: getStyledValue(faktorVerfahrensGebuehrGegner, 'number', 1),
                calcValue: faktorVerfahrensGebuehrGegner,
                editValue: getEditValueFromCalcValue(faktorVerfahrensGebuehrGegner),
                styledValue: getStyledValue(faktorVerfahrensGebuehrGegner, 'number', 1),
            }
        }
    })

    return {
        value,
        newState
    }
}

export default calcVerfahrensGebuehrFaktor