import React from 'react'

const FinanzierungsanfrageButton = (props) => {
    if (props.streitwert.calcValue < 500000) {
        return null
    }

    return (
        <a 
            href="https://www.roland-prozessfinanz.de/anfrage-kontakt/" 
            className="inquiry-button" 
            target="_blank"
            rel="noopener noreferrer"
            >
            Finanzierungsanfrage
        </a>
    )
}

export default FinanzierungsanfrageButton