import React from 'react'

import CalculatorSection1 from './Sections/CalculatorSection1'
import CalculatorSection2 from './Sections/CalculatorSection2'
import CalculatorSection3 from './Sections/CalculatorSection3'
import CalculatorSection4 from './Sections/CalculatorSection4'
import Flyout from '../StaticComponents/Flyout';

const CalculatorForm = (props) => {
    const dataSection1 = {
        data: {
            streitwert: props.data.streitwert,
            anzahlMandanten: props.data.anzahlMandanten,
            mandantenUst: props.data.mandantenUst,
            gemAngelegenheit: props.data.gemAngelegenheit,
            showGemAngelegenheit: props.data.showGemAngelegenheit,
            anzahlGegner: props.data.anzahlGegner,
            gegnerUst: props.data.gegnerUst,
            anzahlGegnerRA: props.data.anzahlGegnerRA,
            gesamtprozesskosten: props.data.gesamtprozesskosten,

        },
        mobile: props.data.mobile,
        handles: props.handles
    }

    const dataSection2 = {
        data: {
            stufeAG: props.data.stufeAG,
            stufeI1: props.data.stufeI1,
            stufeI2: props.data.stufeI2,
            stufeI3: props.data.stufeI3,
            stufeVergleich: props.data.stufeVergleich,
            geb13Rvg: props.data.geb13Rvg,
            geb13RvgGedeckelt: props.data.geb13RvgGedeckelt,
            geb34Gkg: props.data.geb34Gkg,
            gebuehrenOrdnung: props.data.gebuehrenOrdnung
        },
        mobile: props.data.mobile,
        handles: props.handles
    }

    const dataSection3 = {
        data: {
            streitwert: props.data.streitwert,
            gesamtkostenEigenerAnwalt: props.data.gesamtkostenEigenerAnwalt,
            gesamtKostenEigenerAnwaltValue: props.data.gesamtKostenEigenerAnwaltValue,
            mehrvergleich: props.data.mehrvergleich,
            gesamtkostenFremdAnwalt: props.data.gesamtkostenFremdAnwalt,
            honorar: props.data.honorar,
            erfolgshonorar: props.data.erfolgshonorar,
            erfolgsHonorarAG: props.data.erfolgsHonorarAG,
            erfolgsHonorarI1: props.data.erfolgsHonorarI1,
            erfolgsHonorarI2: props.data.erfolgsHonorarI2,
            erfolgsHonorarI3: props.data.erfolgsHonorarI3,
            erfolgsHonorarAGValue: props.data.erfolgsHonorarAGValue,
            erfolgsHonorarI1Value: props.data.erfolgsHonorarI1Value,
            erfolgsHonorarI2Value: props.data.erfolgsHonorarI2Value,
            erfolgsHonorarI3Value: props.data.erfolgsHonorarI3Value,
            nichtErfolgsHonorarI1: props.data.nichtErfolgsHonorarI1,
            nichtErfolgsHonorarI2: props.data.nichtErfolgsHonorarI2,
            nichtErfolgsHonorarI3: props.data.nichtErfolgsHonorarI3,
            nichtErfolgsHonorarI1Value: props.data.nichtErfolgsHonorarI1Value,
            nichtErfolgsHonorarI2Value: props.data.nichtErfolgsHonorarI2Value,
            nichtErfolgsHonorarI3Value: props.data.nichtErfolgsHonorarI3Value,
            stufeAG: props.data.stufeAG,
            stufeI1: props.data.stufeI1,
            stufeI2: props.data.stufeI2,
            stufeI3: props.data.stufeI3,
            streitwertI1: props.data.streitwertI1,
            streitwertI2: props.data.streitwertI2,
            streitwertI3: props.data.streitwertI3,
            mehrvergleichI1: props.data.mehrvergleichI1,
            mehrvergleichI2: props.data.mehrvergleichI2,
            mehrvergleichI3: props.data.mehrvergleichI3,
            verfahrensGebuehrvv3100I1: props.data.verfahrensGebuehrvv3100I1,
            verfahrensGebuehrvv3100I2: props.data.verfahrensGebuehrvv3100I2,
            verfahrensGebuehrvv3100I3: props.data.verfahrensGebuehrvv3100I3,
            verfahrensGebuehrvv3100I1Value: props.data.verfahrensGebuehrvv3100I1Value,
            verfahrensGebuehrvv3100I2Value: props.data.verfahrensGebuehrvv3100I2Value,
            verfahrensGebuehrvv3100I3Value: props.data.verfahrensGebuehrvv3100I3Value,
            diffVFGebuehrI1: props.data.diffVFGebuehrI1,
            diffVFGebuehrI2: props.data.diffVFGebuehrI2,
            diffVFGebuehrI3: props.data.diffVFGebuehrI3,
            gaDiffVFGebuehrI1: props.data.gaDiffVFGebuehrI1,
            gaDiffVFGebuehrI2: props.data.gaDiffVFGebuehrI2,
            gaDiffVFGebuehrI3: props.data.gaDiffVFGebuehrI3,
            abzugAnrechTeil: props.data.abzugAnrechTeil,
            terminsGebuehrI1: props.data.terminsGebuehrI1,
            terminsGebuehrI2: props.data.terminsGebuehrI2,
            terminsGebuehrI3: props.data.terminsGebuehrI3,
            terminsGebuehrI1Value: props.data.terminsGebuehrI1Value,
            terminsGebuehrI2Value: props.data.terminsGebuehrI2Value,
            terminsGebuehrI3Value: props.data.terminsGebuehrI3Value,
            gesamtkostenFremdAnwaltValue: props.data.gesamtkostenFremdAnwaltValue,
            gaVerfahrensGebuehrvv3100I1: props.data.gaVerfahrensGebuehrvv3100I1,
            gaVerfahrensGebuehrvv3100I2: props.data.gaVerfahrensGebuehrvv3100I2,
            gaVerfahrensGebuehrvv3100I3: props.data.gaVerfahrensGebuehrvv3100I3,
            zusatzGebuehrVV1010I1: props.data.zusatzGebuehrVV1010I1,
            zusatzGebuehrVV1010I2: props.data.zusatzGebuehrVV1010I2,
            zusatzGebuehrVV1010I3: props.data.zusatzGebuehrVV1010I3,
            zusatzGebuehrVV1010I1Value: props.data.zusatzGebuehrVV1010I1Value,
            zusatzGebuehrVV1010I2Value: props.data.zusatzGebuehrVV1010I2Value,
            zusatzGebuehrVV1010I3Value: props.data.zusatzGebuehrVV1010I3Value,
            gaZusatzGebuehrVV1010I1Value: props.data.gaZusatzGebuehrVV1010I1Value,
            gaZusatzGebuehrVV1010I2Value: props.data.gaZusatzGebuehrVV1010I2Value,
            gaZusatzGebuehrVV1010I3Value: props.data.gaZusatzGebuehrVV1010I3Value,
            einigungsGebuehrI1: props.data.einigungsGebuehrI1,
            einigungsGebuehrI2: props.data.einigungsGebuehrI2,
            einigungsGebuehrI3: props.data.einigungsGebuehrI3,
            gerichtsverfahrenKVI1: props.data.gerichtsverfahrenKVI1,
            gerichtsverfahrenKVI2: props.data.gerichtsverfahrenKVI2,
            gerichtsverfahrenKVI3: props.data.gerichtsverfahrenKVI3,
            einigungsGebuehrI1Value: props.data.einigungsGebuehrI1Value,
            einigungsGebuehrI2Value: props.data.einigungsGebuehrI2Value,
            einigungsGebuehrI3Value: props.data.einigungsGebuehrI3Value,
            gaEinigungsGebuehrI1Value: props.data.gaEinigungsGebuehrI1Value,
            gaEinigungsGebuehrI2Value: props.data.gaEinigungsGebuehrI2Value,
            gaEinigungsGebuehrI3Value: props.data.gaEinigungsGebuehrI3Value,
            diffEinigungsGebuehrI1: props.data.diffEinigungsGebuehrI1,
            diffEinigungsGebuehrI2: props.data.diffEinigungsGebuehrI2,
            diffEinigungsGebuehrI3: props.data.diffEinigungsGebuehrI3,
            profiGebuehr: props.data.profiGebuehr,
            profiGebuehrI1: props.data.profiGebuehrI1,
            profiGebuehrI2: props.data.profiGebuehrI2,
            profiGebuehrI3: props.data.profiGebuehrI3,
            auslagenI1: props.data.auslagenI1,
            auslagenI2: props.data.auslagenI2,
            auslagenI3: props.data.auslagenI3,
            sonstKostenI1: props.data.sonstKostenI1,
            sonstKostenI2: props.data.sonstKostenI2,
            sonstKostenI3: props.data.sonstKostenI3,
            gaVerfahrensGebuehrvv3100I1Value: props.data.gaVerfahrensGebuehrvv3100I1Value,
            gaVerfahrensGebuehrvv3100I2Value: props.data.gaVerfahrensGebuehrvv3100I2Value,
            gaVerfahrensGebuehrvv3100I3Value: props.data.gaVerfahrensGebuehrvv3100I3Value,
            privGutachtenI1: props.data.privGutachtenI1,
            privGutachtenI2: props.data.privGutachtenI2,
            privGutachtenI3: props.data.privGutachtenI3,
            Ust19I1: props.data.Ust19I1,
            Ust19I2: props.data.Ust19I2,
            Ust19I3: props.data.Ust19I3,
            gesamtEigenerAI1: props.data.gesamtEigenerAI1,
            gesamtEigenerAI2: props.data.gesamtEigenerAI2,
            gesamtEigenerAI3: props.data.gesamtEigenerAI3,
            gaTerminsGebuehrI1: props.data.gaTerminsGebuehrI1,
            gaTerminsGebuehrI2: props.data.gaTerminsGebuehrI2,
            gaTerminsGebuehrI3: props.data.gaTerminsGebuehrI3,
            gaTerminsGebuehrI1Value: props.data.gaTerminsGebuehrI1Value,
            gaTerminsGebuehrI2Value: props.data.gaTerminsGebuehrI2Value,
            gaTerminsGebuehrI3Value: props.data.gaTerminsGebuehrI3Value,
            gaAuslagenAG: props.data.gaAuslagenAG,
            gaAuslagenI1: props.data.gaAuslagenI1,
            gaAuslagenI2: props.data.gaAuslagenI2,
            gaAuslagenI3: props.data.gaAuslagenI3,
            gaSonstKostenI1: props.data.gaSonstKostenI1,
            gaSonstKostenI2: props.data.gaSonstKostenI2,
            gaSonstKostenI3: props.data.gaSonstKostenI3,
            gaUst19AG: props.data.gaUst19AG,
            gaUst19I1: props.data.gaUst19I1,
            gaUst19I2: props.data.gaUst19I2,
            gaUst19I3: props.data.gaUst19I3,
            gesamtkostenGericht: props.data.gesamtkostenGericht,
            gesamtkostenGerichtValue: props.data.gesamtkostenGerichtValue,
            gerichtsverfahrenKVI1Value: props.data.gerichtsverfahrenKVI1Value,
            gerichtsverfahrenKVI2Value: props.data.gerichtsverfahrenKVI2Value,
            gerichtsverfahrenKVI3Value: props.data.gerichtsverfahrenKVI3Value,
            sachverstaendigerI1: props.data.sachverstaendigerI1,
            sachverstaendigerI2: props.data.sachverstaendigerI2,
            sachverstaendigerI3: props.data.sachverstaendigerI3,
            gerichtskostenGesamtI1: props.data.gerichtskostenGesamtI1,
            gerichtskostenGesamtI2: props.data.gerichtskostenGesamtI2,
            gerichtskostenGesamtI3: props.data.gerichtskostenGesamtI3,
            geschaeftsGebuehrvv2300: props.data.geschaeftsGebuehrvv2300,
            geschaeftsGebuehrvv2300Value: props.data.geschaeftsGebuehrvv2300Value,
            gaGeschaeftsGebuehrvv2300: props.data.gaGeschaeftsGebuehrvv2300,
            gaGeschaeftsGebuehrvv2300Value: props.data.gaGeschaeftsGebuehrvv2300Value,
            einigungsGebuehrAG: props.data.einigungsGebuehrAG,
            einigungsGebuehrAGValue: props.data.einigungsGebuehrAGValue,
            profiGebuehrAG: props.data.profiGebuehrAG,
            auslagenAG: props.data.auslagenAG,
            privGutachtenAG: props.data.privGutachtenAG,
            Ust19AG: props.data.Ust19AG,
            gesamtEigenerAAG: props.data.gesamtEigenerAAG,
            nichtErfolgsHonorarAG: props.data.nichtErfolgsHonorarAG,
            nichtErfolgsHonorarAGValue: props.data.nichtErfolgsHonorarAGValue,
            gesamtEigenerErfolgAG: props.data.gesamtEigenerErfolgAG,
            gesamtEigenerErfolgI1: props.data.gesamtEigenerErfolgI1,
            gesamtEigenerErfolgI2: props.data.gesamtEigenerErfolgI2,
            gesamtEigenerErfolgI3: props.data.gesamtEigenerErfolgI3,
            gesamtEigenerNichtErfolgAG: props.data.gesamtEigenerNichtErfolgAG,
            gesamtEigenerNichtErfolgI1: props.data.gesamtEigenerNichtErfolgI1,
            gesamtEigenerNichtErfolgI2: props.data.gesamtEigenerNichtErfolgI2,
            gesamtEigenerNichtErfolgI3: props.data.gesamtEigenerNichtErfolgI3,
            hourlyHours: props.data.hourlyHours,
            hourlyRate: props.data.hourlyRate,
            gesamtFremdAAG: props.data.gesamtFremdAAG,
            gesamtFremdAI1: props.data.gesamtFremdAI1,
            gesamtFremdAI2: props.data.gesamtFremdAI2,
            gesamtFremdAI3: props.data.gesamtFremdAI3,
            gaDiffEinigungsGebuehrI1: props.data.gaDiffEinigungsGebuehrI1,
            gaDiffEinigungsGebuehrI2: props.data.gaDiffEinigungsGebuehrI2,
            gaDiffEinigungsGebuehrI3: props.data.gaDiffEinigungsGebuehrI3,
            gebuehrenOrdnung: props.data.gebuehrenOrdnung,
            ubv: props.data.ubv,
            ubvVerfahrensGebuehrI1: props.data.ubvVerfahrensGebuehrI1,
            ubvVerfahrensGebuehrI2: props.data.ubvVerfahrensGebuehrI2,
            ubvVerfahrensGebuehrI3: props.data.ubvVerfahrensGebuehrI3,
            ubvTerminsGebuehrI1: props.data.ubvTerminsGebuehrI1,
            ubvTerminsGebuehrI2: props.data.ubvTerminsGebuehrI2,
            ubvTerminsGebuehrI3: props.data.ubvTerminsGebuehrI3,
            ubvVerfahrensGebuehrI1Value: props.data.ubvVerfahrensGebuehrI1Value,
            ubvVerfahrensGebuehrI2Value: props.data.ubvVerfahrensGebuehrI2Value,
            ubvVerfahrensGebuehrI3Value: props.data.ubvVerfahrensGebuehrI3Value,
            ubvTerminsGebuehrI1Value: props.data.ubvTerminsGebuehrI1Value,
            ubvTerminsGebuehrI2Value: props.data.ubvTerminsGebuehrI2Value,
            ubvTerminsGebuehrI3Value: props.data.ubvTerminsGebuehrI3Value,
            ubvAuslagenI1: props.data.ubvAuslagenI1,
            ubvAuslagenI2: props.data.ubvAuslagenI2,
            ubvAuslagenI3: props.data.ubvAuslagenI3
        },
        mobile: props.data.mobile,
        handles: props.handles
    }

    const dataSection4 = {
        data: {
            stufeAG: props.data.stufeAG,
            stufeI1: props.data.stufeI1,
            stufeI2: props.data.stufeI2,
            stufeI3: props.data.stufeI3,
            streitwertI1: props.data.streitwertI1,
            streitwertI2: props.data.streitwertI2,
            streitwertI3: props.data.streitwertI3,
            summeAussergerichtlich: props.data.summeAussergerichtlich,
            summeI1: props.data.summeI1,
            summeI2: props.data.summeI2,
            summeI3: props.data.summeI3,
            gesamtprozesskosten: props.data.gesamtprozesskosten,
            geb13Rvg: props.data.geb13Rvg,
            geb13RvgGedeckelt: props.data.geb13RvgGedeckelt,
            geb34Gkg: props.data.geb34Gkg,
            gebuehrenOrdnung: props.data.gebuehrenOrdnung
        },
        mobile: props.data.mobile,
        handles: props.handles
    }

    const dataSection5 = {
        mobile: props.data.mobile,
    }

    const dataFlyout = {
        stufeAG: props.data.stufeAG,
        stufeI1: props.data.stufeI1,
        stufeI2: props.data.stufeI2,
        stufeI3: props.data.stufeI3,
        streitwertI1: props.data.streitwertI1,
        streitwertI2: props.data.streitwertI2,
        streitwertI3: props.data.streitwertI3,
        summeAussergerichtlich: props.data.summeAussergerichtlich,
        summeI1: props.data.summeI1,
        summeI2: props.data.summeI2,
        summeI3: props.data.summeI3,
        gesamtprozesskosten: props.data.gesamtprozesskosten,
        mobile: props.data.mobile,
        handles: props.handles
    }

    return (
        <>
            <CalculatorSection1 {...dataSection1} />
            <CalculatorSection2 {...dataSection2} />
            <CalculatorSection3 {...dataSection3} />
            <CalculatorSection4 {...dataSection4} />

            <Flyout {...dataFlyout} />
        </>
    )
}

export default CalculatorForm