import React from 'react'

const OutputFieldInline = (props) => {
    let style = {
        backgroundColor: props.backgroundColor
    }

    return (
        <div className="output-field-inline" style={style}>
            <div className="output-field-inline__value">
                {props.value}
            </div>
        </div>
    )
}

export default OutputFieldInline