import React from 'react'

import InputField from './InputField'
import OutputField from '../OutputFields/OutputField';

const InputOutputField = (props) => {
    return (
        <div className="form-field__io">
             <label className="form-field__label form-field__label--io">
                <span className="form-field__label-text">{props.label}</span>
                <div className="form-field__io-columns">
                    <InputField
                        decimals={props.decimals}
                        id={props.inputId}
                        name={props.inputName}
                        value={props.inputValue} 
                        handleInput={props.handleInput}
                        handleFocus={props.handleFocus}
                        handleBlur={props.handleBlur}
                        textAlign="center"
                        type={props.type}
                    />
                    <OutputField
                        value={props.outputValue}
                    />
                </div>
             </label>
        </div>
    )
}

export default InputOutputField