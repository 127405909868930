import React from 'react'

class RadioButton extends React.Component {
    onClick(event) {
        event.stopPropagation()
    }

    render() {
        let classes = ['form-field', 'form-field--radio']
        classes = classes.concat(this.props.classes)

        const style = {
            opacity: this.props.opacity
        }

        return(
            <div className={classes.join(' ')} style={style}>
                <label className="form-field__label form-field__label--radio form-field__radio" onClick={this.onClick}>
                    <input 
                        checked={this.props.checked}
                        className="form-field__native-radio" 
                        data-category={this.props.category}
                        name={this.props.name}
                        value={this.props.value} 
                        onChange={
                            event => {
                                this.props.handleChange(event)
                            }
                        }
                        type="radio" 
                    />
                    <span className="form-field__radio-icon"></span>
                    <span className="form-field__radio-text">{this.props.label}</span>
                </label>
            </div>
        )
    }
}

export default RadioButton