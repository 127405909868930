import {getStyledValue} from '../Helpers/valueHelpers'

function calcAnrechenbarerTeil() {
    let anrechenbarerTeil = 0

    if (this.state.stufeAG.checked) {
        const factor = Math.min(this.state.geschaeftsGebuehrvv2300.calcValue / 2, .75)

        anrechenbarerTeil = factor * this.state.geb13RvgI1.calcValue
    }

    return {
        value: {
            eigenerAnwalt: {
                I1: -1 * anrechenbarerTeil
            },
            gegnerAnwalt: {},
            gericht: {}
        },
        newState: {
            abzugAnrechTeil: {
                calcValue: anrechenbarerTeil,
                styledValue: getStyledValue(anrechenbarerTeil, 'euro', 2),
            }
        }
    }
}

export default calcAnrechenbarerTeil