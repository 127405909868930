import {getStyledValue} from '../Helpers/valueHelpers'

function calcEinigungsGebuehr() {
    const instances = ['AG', 'I1', 'I2', 'I3']

    const faktoren = {
        I1: 1,
        I2: 1.3,
        I3: 1.3
    }

    let value = {
        eigenerAnwalt: {},
        gegnerAnwalt: {},
        gericht: {}
    }

    let newState = {}

    instances.forEach((instance) => {
        let einigungsGebuehr = 0
        let einigungsGebuehrState = {}

        if (instance !== 'AG') {
            if (this.state['einigungsGebuehr' + instance].checked) {
                einigungsGebuehr = faktoren[instance] * this.state['geb13Rvg' + instance].calcValue
            }
    
            einigungsGebuehrState = {
                ['einigungsGebuehr' + instance + 'Value']: {
                    calcValue: einigungsGebuehr,
                    styledValue: getStyledValue(einigungsGebuehr, 'euro', 2),
                },
                ['gaEinigungsGebuehr' + instance + 'Value']: {
                    calcValue: einigungsGebuehr,
                    styledValue: getStyledValue(einigungsGebuehr, 'euro', 2),
                }    
            }
        }

        if (instance === 'AG') {
            if (this.state.einigungsGebuehrAG.checked) {
                einigungsGebuehr = 1.5 * this.state.geb13Rvg.calcValue
            }
            
            einigungsGebuehrState = {
                einigungsGebuehrAGValue: {
                    calcValue: einigungsGebuehr,
                    styledValue: getStyledValue(einigungsGebuehr, 'euro', 2),
                },
            }
        }

        newState = {
            ...newState,
            ...einigungsGebuehrState
        }

        value.eigenerAnwalt[instance] = einigungsGebuehr
    })


    return {
        value,
        newState
    }
}

export default calcEinigungsGebuehr